.schedule-content-class {
  .border-line {
    margin-bottom: 0;
  }
  .assign-modal .assign-modal-body .assign {
    &-admin {
      .form-check .form-label {
        &:after {
        }
      }
      [type='radio']:checked + label:after {
        top: 4.8px;
      }
      [type='radio']:not(:checked) + label:after {
        left: 1px;
        top: 5px;
        pointer-events: none;
      }
    }
    &-private-group {
      .form-group {
        // margin: 0;
        padding: 0;
        &:after {
          display: none;
        }
        .form-control {
          margin-bottom: 1.5rem;
          margin-top: -0.5rem;
        }
      }
    }
    &-individual {
      .assign-form {
        margin-top: 1.5rem;
        .assign-modal-textarea {
          min-height: 50vh;
          max-width: 16rem;
        }
      }
    }
  }
}
