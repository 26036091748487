$color-white: #fff;
$color-black: #000;
$color-dark: #343434;
$color-dark1: #1e1e1e;
$color-dark2: #545454;
$color-empty-cart-text: #46535e;
$color-dark-grey: #4a4947;
$color-green: #49c8aa;
$color-grey: #727272;
$color-grey2: #909090;
$color-grey3: #544141;
$color-grey4: #634f4f;
$color-grey5: #1d1d1d;
$color-grey6: #363636;
$color-grey7: #d5d5d5;
$color-grey8: #ecebeb;
$color-grey9: #bcbcbc;
$color-body-bg: #f1f1f1;
$color-navy: #48535d;
$color-orange: #ff6243;
$color-orange0: #707070;
$color-orange1: #ffc551;
$color-orange2: #ff5b00;
$color-orange3: #e84b05;
$color-orange4: #fff2eb;
$color-orange5: #ffa200;
$color-orange6: #ff9100;
$color-orange7: #fbd34d;
$color-orange8: #fcf7e4;
$color-orange9: #f7dd84;
$color-orange10: #ef4b01;
$color-orange11: #ffaf0e;
$color-orange12: #984516;
$color-orange13: #fff7f3;
$color-orange14: #ff7121;
$color-orange15: #eb5400;
$color-orange16: #f25008;
$color-yellow1: #fffbf1;
$color-yellow2: #ffd016;
$color-purple: #c071c5;
$color-purple3: #b487b5;
$color-purple4: #d4a8d6;
$color-purple5: linear-gradient(to right, #B487B5, #A76EAA);
$color-purple6: #994A9E


