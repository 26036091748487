// Import Global Styles
@import '../../assets/scss/Colors.scss';
@import '../../assets/scss/Fonts.scss';

// Worksheet Type | Homework: H | Modul: M | Test: T|
.worksheet-type {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: inline-block;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  font-size: 19px;
  color: $color-white;
  margin-right: 1rem;
  vertical-align: middle;

  &--homework {
    background-color: $color-green;
  }

  &--test {
    background-color: $color-orange14;
  }

  &--mocktest {
    background-color: $color-yellow2;
  }

  &--interval,
  &--total,
  &--notime {
    background-color: #fff;
    img {
      height: 25px;
      width: 25px;
    }
  }
}
