// Color Variables
@import './Colors.scss';

// Font Family
@import './Fonts.scss';

// Custom Variables
@import './Variables.scss';

// Function and Mixins
@import './Functions.scss';

// Icon Lists
@import './Icons.scss';

// HTML, Body and Pseudo Elements
@import './Global.scss';

.form-control {
  box-shadow: none !important;
  color: $color-dark;
}

.btn {
  border-radius: 2px;
  box-shadow: none !important;

  &-primary {
    background-color: $color-orange2;
    border-color: $color-orange2;

    &:focus,
    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background-color: $color-orange10;
      border-color: $color-orange10;
    }
  }

  &-grey-dropdown {
    background-color: none;
    border-color: none;
    color: #fff;

    .dropdown-toggle::after {
      display: none;
    }

    &:focus,
    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      background-color: #292a2a;
      border-color: #292a2a;
      color: #fff;
    }

    &::after {
      display: none;
    }
  }

  &-outline-secondary {
    border-color: rgba($color-grey, 0.6);
    color: $color-grey;

    &:focus,
    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      border-color: $color-grey;
      background-color: $color-grey;
    }
  }

  &-outline-primary {
    border-color: $color-orange2;
    color: $color-orange2;

    &:focus,
    &:hover,
    .show.dropdown-hover > &.dropdown-toggle {
      background-color: $color-orange4;
      border-color: $color-orange2;
      color: $color-orange2;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show:not(.dropdown-hover) > &.dropdown-toggle {
      border-color: $color-orange2;
      background-color: $color-orange2;
      color: $color-white;
    }
  }

  &-link {
    color: $color-orange2;

    &:hover {
      color: $color-orange3;
    }
  }

  &-xs {
    font-size: rem-calc(12);
    height: rem-calc(24);
    line-height: rem-calc(24);
    padding: 0 4px;
  }

  &-sm {
    font-size: rem-calc(12);
  }

  &-md {
    font-size: rem-calc(14);
    line-height: 1.4285;
    padding: 4px 12px;
  }

  &-lg {
    font-size: rem-calc(18);
    line-height: 1.5556;
    padding: 10px 20px;
    min-width: 200px;
  }

  &-icon {
    padding: 0;
    line-height: 1;
    vertical-align: -3px;
    border: 0;

    i {
      display: block;
    }
  }

  &.fz14 {
    font-size: rem-calc(14);
    line-height: 1.714;
  }

  &-confirm {
    background-color: $color-orange5;
    border-color: $color-orange5;

    &:hover {
      background-color: $color-orange6;
      border-color: $color-orange6;
    }
  }
}

.custom-control-label {
  color: $color-dark;

  &::before {
    border-color: $color-dark;
    top: rem-calc(2);

    .custom-checkbox & {
      border-radius: 0;
    }
  }

  &::after {
    top: rem-calc(2);
  }
}

.custom-control-input {
  &:checked ~ .custom-control-label::before {
    background-color: $color-white;
    border-color: $color-orange2;
  }
}

.custom-checkbox {
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FF5B00' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        color: $color-dark;
      }

      &:checked {
        ~ .custom-control-label {
          &::before {
            background-color: $color-white;
          }
        }
      }
    }
  }
}

.dropdown {
  &-menu {
    border-radius: 0;
    border: 0;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
    font-size: rem-calc(13);
  }

  &-lg {
    .dropdown-menu {
      width: 375px;
      padding: 10px 2px 10px 5px;
      margin-top: 10px;

      &::before {
        position: absolute;
        content: '';
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $color-white;
        bottom: 100%;
        left: 50%;
        margin-left: -10px;
      }

      &[x-placement='top-start'] {
        margin-top: 0;
        margin-bottom: 16px;

        &::before {
          border-top: 8px solid $color-white;
          top: 100%;
          bottom: auto;
        }
      }

      &[x-placement='bottom-start'] {
        margin-top: 16px;
        margin-bottom: 0;

        &::before {
          border-bottom: 8px solid $color-white;
          bottom: 100%;
          top: auto;
          left: 24px;
        }
      }

      &.dropdown-menu-right::before {
        left: auto;
        right: 70px;
        margin-left: 0;
      }

      &.dropdown-menu-left::before {
        right: auto;
        left: 70px;
        margin-right: 0;
      }
    }
  }

  &-toggle {
    &.btn-link {
      padding-left: 0;
    }

    &::after {
      border: 0;
      width: 8px;
      height: 5px;
      background: url('../img/icon-down.svg') no-repeat;
      background-size: contain;
      vertical-align: 2px;
    }
  }
}

.square-orange {
  width: 7px;
  height: 7px;
  background-color: $color-orange2;
  display: inline-block;
}

.bg-pattern {
  background: $color-body-bg;

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
}

.middle {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 200;
}

/* #endregion */

/* #region Navbar */
.navbar {
  padding: 0px 20px;
  background-color: $color-dark-grey;
  box-shadow: 0px 1px 4px rgba($color-black, 0.16);

  &-brand {
    img {
      height: 38px;
    }
  }

  &-expand {
    .navbar-width {
      display: flex;
      width: 60%;
      justify-content: center;
    }
    .navbar-nav {
      .nav-item {
        // padding: 0 10px;

        @media (min-width: 769px) and (max-width: 1220px) {
          padding: 0 1em;
        }

        @media (min-width: 1221px) and (max-width: 1365.98px) {
          padding: 0;
        }

        .nav-link {
          padding: 0.85em 1.52em;
        }

        .main-menu {
          height: 53px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .nav-notif {
          padding-top: 0.95em;
          padding-bottom: 0.95em;
        }

        .icon-browse--right-header {
          border: none;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  &-nav {
    .nav-item {
      // padding-left: 15px;
      // padding-right: 15px;

      .nav-link {
        font-size: rem-calc(16);
        color: $color-white;
        position: relative;

        &:hover {
          color: $color-orange11;
          background-color: $color-dark;

          .icon {
            &-bell {
              background-image: url('../img/icon-bell-active.svg');
            }

            &-cart {
              background-image: url('../img/icon-cart-active.svg');
            }

            &-cart-filled {
              background-image: url('../img/icon-cart-filled-active.svg');
            }
          }
        }

        i {
          margin-right: 8px;

          &.icon-counter {
            display: block;
            margin-right: 0;
          }
        }

        img {
          display: block;
          max-width: 100%;
        }

        .number {
          position: absolute;
          top: 10px;
          right: 4px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $color-orange2;
          color: $color-white;
          font-size: rem-calc(12);
          text-align: center;
          line-height: 20px;
        }
      }

      &.avatar {
        margin-left: 8px;

        .nav-link {
          padding: 0;
          width: 40px;
          border-radius: 50%;
          box-shadow: 0px 3px 6px rgba($color-black, 0.16);
          overflow: hidden;
          margin: 6px 0;
        }
      }

      &.active {
        .nav-link {
          color: $color-orange11;
          background-color: $color-dark;
        }
      }
    }

    .dropdown-menu {
      background-color: $color-dark-grey;
      padding: 0;

      &.show {
        top: 7px !important;
      }

      .dropdown-menu-arrow {
        top: -17px;
        // left: 75%;
        width: 0;
        height: 0;
        position: absolute;

        &:before,
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-width: 7px 8px;
          border-style: solid;
          border-color: transparent;
          z-index: 1001;
        }

        &:before {
          bottom: -17px;
          right: -8px;
          border-bottom-color: rgba(0, 0, 0, 0.15);
        }

        &:after {
          bottom: -18px;
          right: -8px;
          border-bottom-color: $color-dark-grey;
        }
      }

      .lang-arrow {
        margin-left: 2rem;
      }

      .profile-arrow {
        right: 1.5rem;
      }

      .teacher-dropdown {
        &:before {
          border-bottom-color: transparent !important;
        }
        &:after {
          right: -25px !important;
        }
      }

      .dropdown-divider {
        margin: 0;
        border-top: 1px solid $color-grey2;
      }

      .chng-log {
        text-align: center;
      }
      .lang-active {
        background: #343434;
      }
      .dropdown-item {
        padding: 0.8rem 1.5rem;

        &:hover {
          background-color: $color-dark;
          color: $color-orange11;

          .icon {
            &-profile {
              background-image: url('../img/icon-profile-active.svg');
            }

            &-global {
              background-image: url('../img/icon-global-active.svg');
            }

            &-logout {
              background-image: url('../img/icon-logout-active.svg');
            }

            &-switch {
              background-image: url('../img/icon-switch-active.svg');
            }
          }
        }

        color: $color-white;
      }
    }
  }
}

@media (max-width: 1220px) {
  .navbar {
    justify-content: space-around;

    &-nav {
      .nav-item {
        padding-left: 5px;
        padding-right: 5px;

        &.text-icon {
          .nav-link {
            span {
              display: none;
            }
          }
        }

        .nav-link {
          font-size: rem-calc(16);

          .number {
            right: 1px;
          }
        }
      }
    }

    &-expand {
      .navbar-nav .nav-link {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .navbar {
    &-nav {
      margin-left: auto;

      &.ml-auto.mr-auto {
        display: none;
      }
    }

    &-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(203, 203, 203, 1)' stroke-width='3' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    &-expand {
      .navbar-nav {
        .nav-item {
          .nav-link {
            padding: 0.85em 0.5em;
          }

          &.avatar {
            .nav-link {
              padding: 0;
            }
          }
        }
      }
      .navbar-toggler {
        display: block;
        border: 0;
        padding: rem-calc(5) 0;
        margin-right: 15px;
      }
    }
  }
}

// @media (max-width: 720px) and (min-width: 640px) {
//   .navbar {
//     &.navbar-expand {
//       .navbar-nav {
//         .nav-item {
//           .nav-link {
//             padding: 0.85em 0.5em;
//           }

//           &.avatar {
//             .nav-link {
//               padding: 0;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 639.98px) {
  // # Navbar Content
  .navbar {
    padding-left: 40px;
    padding-right: 10px;

    .navbar-brand {
      // hide logo koobits
      display: none;
    }
  }

  // navbar expand
  &.navbar-expand {
    .navbar-nav {
      margin-left: auto;
      margin-right: 0;

      .nav-item {
        padding: 0 5px;

        .nav-link {
          text-align: center;
          padding-left: 0.8em;
          padding-right: 0.35em;
        }
      }

      .nav-item.avatar .nav-link {
        padding: 0;
        border-radius: 50% !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        overflow: hidden;
      }

      .dropdown-menu {
        .dropdown-menu-arrow {
          top: -17px;
          left: 94.5%;
        }
      }
    }
  }

  // # Navbar toggle icon menu
  .navbar-toggler-icon {
    width: 1.25em;
    height: 1.25em;
  }
}

@media screen and (max-width: 500px) {
  // # Navbar
  .navbar {
    // padding-top: 5px;
    // padding-bottom: 5px;

    &.navbar-expand {
      overflow-x: auto !important;

      .navbar-nav.mx-lg-auto {
        padding-left: 0.5rem;
      }

      .navbar-nav {
        .avatar-dropdown {
          margin-top: 3px;
        }

        .nav-item {
          padding: 0px 5px;

          .nav-link {
            padding: 5px 0.3em;

            i {
              margin-right: 5px;

              &.icon-bell {
                width: 14px;
                height: 14px;
              }
            }

            .number {
              height: 15px;
              width: 15px;
              line-height: 15px;
              font-size: 0.55rem;
              top: 2px;
              right: 4px;
            }
          }

          .main-menu {
            height: inherit;
          }

          &.avatar {
            .nav-link {
              padding: 0;
              width: 25px;
              border-radius: 50%;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              overflow: hidden;
              margin: 0;
            }
          }
        }

        .dropdown-menu {
          .dropdown-menu-arrow {
            top: -17px;
            left: 94.5%;
          }
        }
      }
    }
  }

  // # Navbar toggle button
  .navbar-toggler {
    padding: 0.05em 0.5em;
    background-color: $color-dark-grey;
  }

  .navbar-toggler-icon {
    width: 0.75em;
    height: 0.75em;
  }
}

@media screen and (max-width: 359.98px) {
  .navbar {
    &.navbar-expand {
      .navbar-nav {
        position: relative;
        top: 4px;

        &.mx-lg-auto {
          padding-left: 1.2rem;
        }
      }
    }
  }
}
/* # end region Navbar */

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1030;
  width: 100%;
  max-width: 230px;
  transition: all 0.4s ease;
  transform: translate3d(-100%, 0, 0);
  background-color: $color-white;

  .navigation-open & {
    transform: translate3d(0, 0, 0);
  }

  &-body {
    padding: 64px 20px 20px;
  }

  .nav {
    margin-bottom: 20px;

    .nav-link {
      font-size: rem-calc(18);
      padding: 10px 0;
      color: $color-grey;

      &:hover,
      &:focus {
        color: $color-orange2;
      }

      i {
        margin-right: 8px;
        width: 20px;
      }
    }

    .active .nav-link {
      color: $color-orange2;
    }
  }

  &-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.3);
    z-index: 1000;
    transition: all 0.4s ease;
    opacity: 0;
    visibility: hidden;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 12px;
    color: $color-grey;
    opacity: 1 !important;
    font-weight: 400;
    font-size: 40px;
    text-shadow: none;
  }
}

.navigation-open {
  overflow: hidden;
  height: 100%;

  .navigation-backdrop {
    opacity: 1;
    visibility: visible;
  }
}

.navigation-arrow {
  display: block;
  position: absolute;
  right: 0;
  top: $navbar-height + 20px;
  z-index: 1000;
  animation-name: bounce;

  animation-duration: 1s;
  animation-iteration-count: infinite;

  @media (max-width: 1199px) {
    right: -1.5em;
  }

  &--saved-worksheet {
    top: 0;
    right: auto;
    left: $sidebar-small-width;

    @media (max-width: 1024.98px) {
      left: 56px;
    }
  }
}

/* #endregion */

/* #region Homepage */
.select-level {
  padding: 70px 0;
  text-align: center;

  h1 {
    font-size: rem-calc(30);
    font-weight: 600;
    color: $color-dark;
    margin-bottom: 50px;
  }
}

.list-level {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;

    label {
      display: flex;
      width: 120px;
      height: 120px;
      margin: 0 auto 70px;
      border-radius: 50%;
      background-color: $color-white;
      border: 1px solid lighten($color-black, 84.8%);
      font-size: rem-calc(30);
      font-weight: 600;
      text-align: center;
      color: $color-dark;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        background: $color-white;
        color: $color-purple;
      }

      &:focus {
        background-color: $color-purple;
        border-color: $color-purple;
        color: $color-white;
        box-shadow: 0px 3px 6px rgba(255, 91, 0, 0.16);
      }
    }

    [type='radio'] {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked ~ label {
        background-color: $color-purple;
        border-color: $color-purple;
        color: $color-white;
        box-shadow: 0px 3px 6px rgba(255, 91, 0, 0.16);
      }
    }
  }
}

/* # Sidebar Region */
.sidebar {
  position: fixed;
  top: $topmenu-height;
  left: 0;
  bottom: 0;
  width: auto; //$sidebar-width;
  z-index: 40;

  &-toggler {
    display: none;
  }

  .close {
    position: absolute;
    left: 20px;
    top: 5px;
    color: $color-white;
    opacity: 1 !important;
    font-weight: 400;
    font-size: 40px;
    text-shadow: none;
  }

  &-right {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    width: auto; //$sidebar-width;
    z-index: 50;
    background-color: #ffffff;
  }

  &-level {
    background: $color-purple5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 15px 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    z-index: 30;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 3px;

        a {
          display: block;
          width: 56px;
          height: 56px;
          color: $color-white;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            color: $color-white;
            text-decoration: none;
          }
        }

        &:hover {
          background-color: darken($color-purple4, 7%);
        }

        &.active a {
          background-color: $color-purple4;
          color: $color-white;
          font-weight: 700;
        }
      }
    }

    .form-control {
      margin-left: 0 !important;
    }

    .col-sidebar-label {
      padding-left: 0;
      padding-right: 0;
      margin-left: 5px;
      margin-right: 5px;

      label {
        width: 62px !important;
        text-align: center !important;
      }
    }
  }

  &-topic {
    position: absolute;
    top: 0;
    left: 55px;
    bottom: 0;
    width: 270px;
    z-index: 20;
    background-color: $color-orange8;
    border-right: 1px solid rgba($color-orange0, 0.35);

    &-header {
      background-color: $color-orange9;
      padding: 9px 10px 8px;
    }

    &-body {
      height: 95%;
      width: 100%;
      overflow-y: auto;
      font-weight: 600;

      .nav {
        padding: 10px 0 25px;

        .nav-link {
          color: rgba($color-dark, 0.8);
          padding: 10px 30px 10px 20px;
          position: relative;

          &:hover {
            color: $color-purple;
            background-color: $color-white;
          }
        }

        .active .nav-link {
          color: $color-purple6;
          background-color: $color-white;
          border-left: 5px solid $color-purple6;
          padding-left: 15px;

          &::after {
            position: absolute;
            content: '';
            right: 20px;
            top: 50%;
            background: url('../img/icon-right-purple.svg') no-repeat;
            width: 5px;
            height: 9px;
            margin-top: -4.5px;
          }
        }
      }
    }
  }
  // for curriculums without levels
  &-nolevel {
    left: 0px;
  }

  label {
    font-weight: 600;
    margin-bottom: 0;
    color: $color-dark;
  }

  .form-control {
    padding: 0 0 0 10px;
    margin-left: -10px;
    border: solid 1px $color-orange7;
    font-size: rem-calc(12);
    appearance: none;
    background: url('../img/icon-down.svg') no-repeat right 0.75rem center/8px
      10px;
    background-color: $color-orange8;
    border-radius: 3px;
  }
  &-create-assign {
    width: 195px;
  }
  &-create-assign__ul {
    @include content-min-height;
    background-color: $color-purple3;

    .list-group-item {
      font-size: rem-calc(15);
      padding: 0;
      background-color: transparent;
      border: none;

      a {
        padding: 0.9em 0 0.9em 1em;
        line-height: 1;
        color: $color-white;
        vertical-align: middle;

        &.nav-link-active,
        &:hover {
          background-color: #7f5c83;

          .icon-saved-worksheet {
            background-image: url('../img/icon-new-saved-worksheet-hover.svg');
          }
        }

        img,
        i {
          display: inline-block;
          width: rem-calc(23);
          height: rem-calc(23);
          margin-right: 0.5em;
          vertical-align: middle;

          &.icon-question-cart {
            position: relative;
            width: rem-calc(24);
            height: rem-calc(24);
            left: -0.2em;
          }

          &.icon-topic-revision {
            position: relative;
            width: rem-calc(18);
            height: rem-calc(18);
            top: -0.1em;
            left: 0.1em;
            margin-right: 1em;
          }
        }
      }
    }
  }
  &-manage-class {
    padding: 15px;
  }

  &-icon {
    &__saved-worksheet {
      width: 26px;
      height: 22px;
      position: relative;
      left: -3px;
    }
  }
}

@media (max-width: 1024.98px) {
  .sidebar {
    width: 56px;

    span {
      display: none;
    }

    .sidebar-create-assign {
      width: auto;

      &__ul {
        width: 56px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .sidebar-toggler {
    position: absolute;
    top: 5px;
    z-index: 1031;
    display: none;
  }

  .sidebar {
    width: 270px;
    transition: all 0.4s ease;
    background-color: $color-dark-grey;

    &--auto {
      width: auto;
    }

    &-create-assign {
      background-color: $color-purple3;
      width: 56px;
      top: 50px;
    }

    &-level {
      bottom: auto;
      box-shadow: none;
      width: 100%;
      padding: 9px 20px 0;
      border-right: 1px solid rgba($color-black, 0.07);
      background-color: $color-orange9;
    }

    &-topic {
      top: 40px;
      left: 0;
    }
    // for curriculums without levels
    &-nolevel {
      top: 0px;
    }

    label {
      width: 72px;
      text-align: right;
    }
  }
}

@media (max-width: 639.98px) {
  //sidebar
  .sidebar-toggler {
    position: fixed;
    top: 2px;
    z-index: 1031;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .sidebar {
    top: 51px;
    transition: all 0.4s ease;
    z-index: 101;
    transform: translate3d(-100%, 0, 0);

    &.sidebar-open {
      transform: translate3d(0, 0, 0);
    }
  }
}

@media screen and (max-width: 575.98px) {
  .sidebar {
    &-manage-class {
      width: 100%;
      max-width: 180px;
    }
  }
}

@media screen and (max-width: 500px) {
  // sidebar content
  .sidebar {
    top: 37px;

    //sidebar toggle
    &-toggler {
      padding: 0.25em 0.5em;
      top: 1px;
    }

    &-right {
      top: 37px;
    }
  }
}

@media screen and (max-width: 479.98px) {
  .sidebar {
    &-manage-class {
      max-width: 160px;
    }
  }
}

@media screen and (max-width: 359.98px) {
  .sidebar {
    top: 50px;

    &-toggler {
      top: 4px;
    }

    &-right {
      top: 55px;
    }
  }
}
/* # end sidebar region */

/* # content */
.content {
  position: relative;
  min-height: calc(100vh - 100px);
  height: auto;
  width: auto;

  &--centered {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &-sidebar-small {
    min-height: calc(100vh - 50px);
    overflow: scroll;

    &.content-manage-class {
      margin-top: -20px;
      margin-left: 235px;
    }
  }

  &-wrap {
    height: calc(
      96vh - 70px + 5px
    ); // Same as .content height, so content skills wont be scrollable

    @media (max-width: 991px) {
      height: 90vh;
    }

    &-scrollpane {
      padding: 0 20px 20px;
      overflow-y: auto;
      height: 100%;

      @media (max-width: 768px) {
        margin-left: 0px;
        padding-left: 60px;
        padding-right: 60px;
      }
    }

    h3 {
      font-size: rem-calc(18);
      color: $color-orange2;
      font-weight: 400;
      margin: 15px 0;

      .skill-name {
        color: $color-black;
      }
    }
  }

  &__inner {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: auto;
  }

  &__inner-question-cart {
    width: calc(100% - 195px);
    margin-right: 0;
    padding: 60px 15px 0 30px;
  }

  &__inner-create-assign {
    width: calc(100vw - 215px);
    margin-right: 0;
    margin-left: auto;
  }

  &__inner-saved-worksheet {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 20px;
    height: calc(100vh - 75px);
  }

  &-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-black;
    opacity: 0.46;
    z-index: 40;

    @media (max-width: 991px) {
      display: block;
    }
  }

  &-manage-class {
    margin-left: 215px;
    padding-right: 245px;
  }
}

@media (max-width: 1024.98px) {
  .content {
    &-wrap {
      &-scrollpane {
        .simplebar-wrapper {
          .simplebar-content {
            padding-left: 35px !important;
            padding-right: 35px !important;
            @media (max-width: 640px) {
              margin-top: 20px;
            }
          }
        }
      }
    }
    &.content-sidebar-small {
      &.content-manage-class {
        padding-left: 70px;
      }
    }
    &.content-sidebar-small {
      margin-left: 0;
    }
    &__inner-create-assign {
      width: calc(100vw - 76px);
    }
    &__inner-question-cart.content-sidebar-small {
      margin-left: auto;
      width: calc(100% - 56px);
      padding-left: 30px;
    }
  }
}

@media (max-width: 991.98px) {
  .content {
    transition: all 0.4s ease;
    padding-left: 20px;

    &--centered {
      margin-left: 270px;
    }

    &-wrap {
      padding: 90px 0 0 !important;

      &-scrollpane {
        .simplebar-wrapper {
          .simplebar-content {
            padding-left: 0 !important;
            padding-right: 20px !important;
          }
        }
      }
    }
    &__inner-question-cart.content-sidebar-small.content--centered {
      min-height: calc(100vh - 50px);
    }
  }
}

@media screen and (max-width: 767.98px) {
  .content {
    &--centered {
      padding-right: 50px;
    }
    &-wrap {
      padding: 120px 0 0 !important;
    }
    &-manage-class {
      margin-right: 0;
    }
    &__inner-question-cart.content-sidebar-small.content--centered {
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 639.98px) {
  .content {
    margin-left: auto;

    &--centered {
      margin-left: 0;
      padding-right: 20px;
    }

    &-wrap {
      padding: 100px 0 0 !important;
    }

    &.content-sidebar-small {
      &.content-manage-class {
        padding-left: 20px;
      }
    }

    &__inner-create-assign {
      width: 100%;
    }

    &__inner-saved-worksheet {
      padding-left: 15px;
    }
    &__inner-question-cart.content-sidebar-small {
      width: 100%;
    }
  }

  .middle {
    width: calc(100vw - 270px);
    left: auto;
    right: 0;
  }
}

@media screen and (max-width: 575.98px) {
  .content {
    &-wrap {
      padding-top: 136px !important;
    }

    &.content-sidebar-none {
      padding-right: 30px;
    }
    &.content-sidebar-small {
      &.content-manage-class {
        padding-right: 180px;
      }
    }
    &__inner-question-cart {
      padding-top: 80px;
    }
  }
}

@media screen and (max-width: 500px) {
  .content {
    &__inner-question-cart {
      padding-top: 102px;
    }
  }
}
@media screen and (max-width: 479px) {
  .content {
    .middle {
      width: calc(100vw - 100px);
      z-index: 100;
    }

    &.content-sidebar-none {
      padding-right: 30px;
    }
    &.content-sidebar-small {
      &.content-manage-class {
        padding-right: 160px;
      }
    }
  }
}
@media screen and (max-width: 399.98px) {
  .content {
    &__inner-question-cart {
      padding-top: 88px;
    }
  }
}
@media screen and (max-width: 359px) {
  .content {
    &__inner-question-cart {
      padding-top: 102px;
    }
  }
}
// end of content region

// pointer animation region
.select-topic {
  text-align: center;

  img {
    margin-bottom: 30px;
    animation: bounceFinger 2s infinite;
    display: inline-block;
    margin-right: 20px;
  }

  h1 {
    font-size: rem-calc(34);
    font-weight: 600;
    color: rgba($color-dark, 0.68);
    display: inline-block;

    &.text-light {
      color: rgba($color-white, 0.68);
    }
  }
}

@media (max-width: 839px) {
  .select-topic {
    h1 {
      display: block;
    }
  }
}

@media screen and (max-width: 639.98px) {
  .select-topic {
    text-align: center;
    margin: 0 0.5rem;

    h1 {
      display: block;
    }
  }
}

@media screen and (max-width: 479.98px) {
  .select-topic {
    img {
      margin-right: auto;
      width: 100%;
      max-width: 160px;
      height: auto;
    }

    h1 {
      font-size: 2rem;
    }
  }
}

@keyframes bounceFinger {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-20px);
  }

  60% {
    transform: translateX(-15px);
  }
}
// end of pointer animation region

// subbar region
.subbar {
  position: fixed;
  top: $topmenu-height;
  left: $sidebar-width;
  background-color: $color-body-bg;
  right: 0;
  z-index: 40;

  &-inner {
    line-height: 23px;
    padding: 0 20px;

    .subbar-inner {
      border-top: 1px solid rgba($color-black, 0.07);
    }

    .custom-control {
      margin-top: 11px;
      margin-bottom: 6px;
    }

    &.info {
      padding: 10px 20px;
      font-size: rem-calc(15);
      background-color: $color-white;
      border-bottom: 1px solid rgba($color-orange0, 0.35);

      .subbar-label {
        width: auto;
        min-width: 110px;

        span {
          font-weight: 600;
        }
      }
    }

    .content-type-label {
      opacity: 0.5;
      margin: 0 -5px;
      font-size: 13px;
    }
  }

  .nav {
    .nav-link {
      color: $color-black;
      padding: 15px 20px 11px;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        padding: 10px 15px 6px;
        margin: 5px;
        color: $color-black;
        background-color: rgba($color-white, 0.7);
      }
    }

    .active .nav-link {
      color: $color-black;
      background-color: $color-white;
    }
  }

  &-label {
    font-weight: 600;
    min-width: 110px;
  }
}

@media (max-width: 1024.98px) {
  .subbar {
    &-inner {
      padding-left: 35px;
      padding-right: 35px;

      &.info {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .subbar {
    transition: all 0.4s ease;
    left: 270px;

    &-inner {
      padding-left: 20px;
      padding-right: 20px;

      &.info {
        padding-left: 20px;
        padding-right: 20px;
      }

      .row.align-items-center {
        .col-sm {
          padding: 0;
        }
        .col-sm-auto {
          margin-top: 3px;
        }
      }
    }

    .nav {
      flex-wrap: nowrap;

      .nav-link {
        padding: 15px 10px 11px;

        &:hover {
          padding: 10px 5px 6px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) and (min-width: 640px) {
  .subbar {
    &-inner {
      padding-left: 5px;
      padding-right: 5px;

      > .row {
        margin-left: auto;
        margin-right: auto;
      }

      &.info {
        > .row {
          margin-left: auto;
          margin-right: auto;

          &.align-items-center {
            .col-md-auto {
              flex: 0 0 auto;
              width: auto;
              max-width: 100%;
            }
            .col-md {
              flex: 0 0 auto;
              width: auto;
              max-width: 100%;
            }
          }
        }
      }

      .content-type-label {
        min-width: 1px;
      }
    }

    .nav {
      .nav-link {
        width: 100%;
        margin: 5px;
        padding: 15px 8px 11px;

        &:hover {
          padding: 15px 8px 11px;
        }

        img.mr-2 {
          margin-right: 0.2rem !important;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 639.98px) {
  .subbar {
    left: 0;

    .nav {
      .nav-link {
        margin: 5px;
        padding: 15px 10px 11px;

        &:hover {
          padding: 15px 10px 11px;
        }
      }
    }

    &-inner.info {
      padding-left: 40px;
    }
  }
}

@media (max-width: 479.98px) {
  .subbar {
    .nav {
      .nav-link {
        font-size: 0;

        img.mr-2 {
          margin-right: 0 !important;
        }
      }
    }
  }
}
// end of subbar region

.dropdown-filter {
  .dropdown-menu {
    left: auto !important;
    right: 100% !important;
    margin-top: 0 !important;
    transform: none !important;
    width: 270px;
    padding: 10px 20px 20px;

    .custom-control-label {
      font-size: rem-calc(14);

      .text-muted {
        font-size: rem-calc(12);
      }
    }
  }

  .btn {
    min-width: 70px;
  }
}

[data-filtergroup] {
  display: none;

  &.active {
    display: block;
  }

  .custom-control-label,
  .subbar-label {
    font-size: rem-calc(12);
  }
}

.play-rounded {
  background-color: $color-orange6;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
}

.box-white {
  border-radius: 5px;
  background-color: $color-white;
  // border: 1px solid rgba(112, 112, 112, 0.14);
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.07);
  color: rgba($color-dark, 0.8);
  margin-bottom: 20px;

  //presentation kooquiz 13/4/2020
  .presentation-kooquiz {
    pointer-events: none;
  }

  &.cursor {
    cursor: pointer;
  }
  a {
    color: rgba($color-dark, 0.8);

    &:hover {
      text-decoration: none;
      color: $color-orange2;
    }
  }

  &-header {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.14);
    font-size: rem-calc(12);
    font-family: 'Helvetica', sans-serif;

    .col-md span {
      &.question-id {
        opacity: 0.5;
      }

      &.difficulty-label {
        opacity: 0.5;
      }

      &.question-difficulty {
        margin-left: 20px;
      }
    }

    .btn-play {
      padding: 5px 10px;
      margin-left: 5px;
      vertical-align: -6px;
      border-radius: 5px;
      background-color: $color-orange11;
      border-color: $color-orange11;

      &:hover {
        background-color: $color-orange6;
        border-color: $color-orange6;
      }

      img {
        display: block;
      }
    }
  }

  &-body {
    padding: 20px 20px 30px;
    overflow-x: auto;
    .media-right {
      width: 32%;
      margin-left: 5%;
      text-align: right;

      img {
        max-width: 100%;
      }
    }

    p {
      color: $color-dark;
      font-size: rem-calc(20);
      font-family: Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 400;
      margin-bottom: rem-calc(10);
    }

    a {
      font-size: rem-calc(12);
    }
  }
  &.assign-content {
    .media {
      .media-right {
        width: 32%;
        margin-left: 5%;
        text-align: right;

        img {
          max-width: 100%;
        }
      }
    }
  }
  &-footer {
    background-color: darken($color-white, 1.1%);
    padding: 8px 20px;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid rgba(112, 112, 112, 0.14);
    font-size: rem-calc(12);

    .col-md span {
      & + span {
        margin-left: 20px;
      }

      i {
        margin-right: 5px;
        position: relative;
        top: -2px;
      }

      &.dropdown {
        display: inline-block;
      }
    }

    .dropdown-toggle {
      height: 29px;
      line-height: 29px;

      &::after {
        display: none;
      }
    }
  }

  &-wrap {
    .row:first-child:last-child .col-xl-6:first-child:last-child {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .right-side {
      @media (max-width: 640px) {
        position: inherit;
      }
    }
  }
}
input[type='checkbox'] {
  width: 20px;
  height: 19px;
  position: absolute;
  z-index: 1;
}

.custom-checkbox {
  min-width: 19px;
  min-height: 19px;
  border: solid 1px $color-orange2;
  background-color: $color-white;
  position: absolute;
}
input[type='checkbox']:checked ~ .custom-checkbox {
  background-color: $color-orange2;
  border: solid 1px $color-orange12;
}

.custom-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}
input[type='checkbox']:checked ~ .custom-checkbox:after {
  display: inline-block;
}

.custom-checkbox:after {
  left: 5px;
  top: -3px;
  width: 8px;
  height: 16px;
  border: solid $color-white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-check {
  padding-left: 2.3rem;

  input[type='checkbox'] {
    top: 1px;
    left: 30px;
    &:checked ~ .form-check-label {
      font-weight: bold;
    }
  }
  &-label {
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    margin-top: 5px;
  }
  .custom-checkbox {
    left: 9px;
    top: 5px;
  }
}

.total-qn {
  margin-bottom: 0;
  position: relative;
  left: -20px;
  top: 2px;
  font-family: 'Muli', sans-serif;
  font-size: 15px;
  &-number {
    font-size: 30px;
    position: absolute;
    top: -13px;
    margin-left: 3px;
    color: $color-orange2;
    font-weight: bold;
    font-family: 'Muli', sans-serif;
  }
}

.btn-next-qn {
  padding: 0.3rem 3rem;
  &:disabled {
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: not-allowed;
  }
}

.question-card.create-assign {
  input[type='checkbox'] {
    top: 3px;
    left: -10px;
  }
  .custom-checkbox {
    top: 8px;
    left: -30px;
  }
  .box-white {
    &.active {
      border-color: $color-orange;
    }
    &-header {
      padding-left: 60px;
    }

    &-body {
      padding-left: 60px;
      padding-bottom: 0;
      input[type='text'] {
        border: none;
        background-color: rgba($color-grey2, 0.2);
        max-width: 70px;
        height: 25px;
      }

      a {
        text-decoration-line: underline;
        font-size: 12px;
        font-family: Helvetica, Arial, sans-serif;
      }
      p {
        color: $color-grey6;
        font-size: 16px;
        font-family: 'Muli', sans-serif;
        font-weight: 600;
      }
      .denominator {
        font-family: Helvetica, Arial, sans-serif;
        font-size: 16px;
      }
    }
  }
  .question {
    &-title {
      font-size: 16px;
      font-family: 'Muli', sans-serif;
      font-weight: bold;
      color: $color-grey5;
    }

    &-id {
      margin-right: 12px;
    }
    &-panel {
      right: -50px;
      top: 30%;
    }
  }
  // Question Right Button
  // .question-action {
  //   right: -44px;
  //   top: 0;
  //   text-align: center;

  //   &__item {
  //     display: block;
  //     width: 39px;
  //     height: 36px;
  //     padding: 11px;
  //     background-color: #ffffff;
  //     color: $color-dark2;
  //     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  //     margin-bottom: 2px;
  //     background-repeat: no-repeat;
  //     background-size: 17px auto;
  //     background-position: center center;
  //     position: relative;

  //     &:hover {
  //       background-color: #fff6ee;
  //       cursor: pointer;

  //       &::after {
  //         font-family: 'Muli', sans-serif;
  //         font-size: 13px;
  //         line-height: 16px;
  //         display: block;
  //         position: absolute;
  //         top: 5px;
  //         background-color: #6b6b6b;
  //         border-radius: 4px;
  //         padding: 5px;
  //         color: #fff;
  //         white-space: nowrap;
  //         right: 45px;
  //       }
  //     }

  //     &:first-child {
  //       border-top-left-radius: 4px;
  //       border-top-right-radius: 4px;
  //     }

  //     &:last-child {
  //       border-bottom-left-radius: 4px;
  //       border-bottom-right-radius: 4px;
  //     }
  //   }

  //   &__menu-section {
  //     padding-left: 0;
  //     padding-right: 0;
  //     background-size: 30px auto;
  //     background-image: url('../img/icon-menu-list.svg');

  //     &:hover {
  //       background-image: url('../img/icon-menu-list-active.svg');

  //       &::after {
  //         content: 'Add Section';
  //       }
  //     }
  //   }

  //   &__menu-up {
  //     background-image: url('../img/icon-menu-up.svg');

  //     &:hover {
  //       background-image: url('../img/icon-menu-up-active.svg');

  //       &::after {
  //         content: 'Bring Forward';
  //       }
  //     }
  //   }

  //   &__menu-up-end {
  //     background-image: url('../img/icon-menu-up-end.svg');

  //     &:hover {
  //       background-image: url('../img/icon-menu-up-end-active.svg');

  //       &::after {
  //         content: 'Move to 1st';
  //       }
  //     }
  //   }

  //   &__menu-down {
  //     background-image: url('../img/icon-menu-down.svg');

  //     &:hover {
  //       background-image: url('../img/icon-menu-down-active.svg');

  //       &::after {
  //         content: 'Send Backward';
  //       }
  //     }
  //   }

  //   &__menu-down-end {
  //     background-image: url('../img/icon-menu-down-end.svg');

  //     &:hover {
  //       background-image: url('../img/icon-menu-down-end-active.svg');

  //       &::after {
  //         content: 'Move to Last';
  //       }
  //     }
  //   }
  // }
  .text-muted {
    color: $color-grey2 !important;
    font-family: 'Muli', sans-serif;
  }
  .help-text {
    display: none;
  }
  @media (max-width: 1280px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.list-qn {
  list-style: none;
  padding: 1px;
  margin: 1px 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 130px;
  overflow: auto;

  li {
    flex: 0 0 10%;
    max-width: 10%;

    &.active {
      a {
        background-color: $color-orange2;
        border-color: $color-orange2;
        color: $color-white;

        span {
          color: $color-white;
        }
      }
    }

    &.selected {
      a {
        background-color: $color-orange4;
        color: $color-dark;
      }
    }

    a {
      display: block;
      text-align: center;
      font-size: rem-calc(10);
      border: 1px solid rgba($color-grey, 0.11);
      padding: 4px 0;
      margin: -1px 0 0 -1px;
      position: relative;

      &:hover {
        text-decoration: none;
        border-color: $color-orange2;
        z-index: 2;
      }

      span {
        display: block;
        font-size: rem-calc(12);
        font-weight: 700;
        color: $color-dark;
      }
    }
  }
}

.list-skills {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  @media (max-width: 640px) {
    margin-top: 0;
  }

  li {
    margin-bottom: 3px;

    a {
      display: block;
      background-color: $color-white;
      font-size: rem-calc(18);
      font-weight: 400;
      line-height: 1.4445;
      padding: 17px 20px;

      &:hover {
        text-decoration: none;
        background-color: $color-yellow1;
      }

      .skill-name {
        color: $color-black;
      }
    }
  }
}

.modal {
  &-qs {
    .modal-dialog {
      max-width: 70%;
    }
  }

  &-content {
    border: 0;
    border-radius: 5px;
  }
  &-backdrop {
    width: 100%;
    height: 100%;
  }
}

.question-item {
  &-header {
    background-color: darken($color-white, 3.5%);
    border-radius: 5px 5px 0 0;
    padding: 15px 20px;
    color: $color-dark;

    h3 {
      font-size: rem-calc(18);
      font-weight: 600;
    }
  }

  &-body {
    .media-body {
      border-right: 1px solid rgba($color-black, 0.1);
      min-height: 300px;
    }

    .media-right {
      width: 200px;

      .dropdown-toggle::after {
        display: none;
      }
    }

    .inner {
      padding: 20px;
    }
  }
}

.qs {
  &-attr {
    padding: 15px 20px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;

    span {
      & + span {
        margin-left: 20px;
      }

      i {
        margin-right: 5px;
        position: relative;
        top: -2px;
      }
    }

    a {
      color: rgba($color-grey6, 0.8);
      &:hover {
        text-decoration: none;
        color: $color-orange2;
      }
    }
  }

  &-text {
    font-size: rem-calc(18);
    font-weight: 600;
    padding: 20px;
    color: $color-dark;

    img {
      max-width: 100%;
    }
  }

  &-tags {
    margin-top: 30px;

    h4 {
      margin-bottom: 10px;
      font-size: rem-calc(16);
      font-weight: 400;
      color: $color-dark;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 -4px;

      li {
        display: inline-block;
        margin: 0 4px 10px;

        a,
        span {
          display: block;
          background-color: $color-orange4;
          color: $color-dark;
          padding: 7px 10px;
          font-size: rem-calc(12);
          line-height: 1.667;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &-solution {
    border-top: 1px solid rgba($color-black, 0.1);
    font-size: rem-calc(16);
    color: $color-dark;
  }
}

[data-toggle='collapse'] {
  .collapse-hide {
    display: inline;
  }

  .collapse-show {
    display: none;
  }

  &.collapsed {
    .collapse-hide {
      display: none;
    }

    .collapse-show {
      display: inline;
    }
  }
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}

.slick {
  // Fix react slick fade bug
  &-initialized &-slide {
    &.slick-active {
      z-index: 1;
    }
  }

  &-arrow {
    border: 0;
    font-size: 0;
    position: absolute;
    top: 50%;
    left: -70px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    margin-top: -30px;
    outline: none !important;
    background-color: darken($color-white, 10%);
    cursor: pointer;
    z-index: 10;

    &:not(.slick-disabled):hover {
      background-color: $color-white;
    }

    &.slick-disabled {
      cursor: default;
      background-color: $color-grey2;
    }
  }

  &-prev::before,
  &-next::before {
    position: absolute;
    content: '';
    width: 8px;
    height: 13px;
    background-repeat: no-repeat;
    background-size: cover;
    left: 50%;
    top: 50%;
    background-image: url('../img/icon-right.svg');
  }

  &-prev::before {
    transform: rotate(180deg) translate(50%, 50%);
  }

  &-next {
    left: auto;
    right: -70px;

    &::before {
      transform: translate(-50%, -50%);
    }
  }

  &-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
      position: relative;

      & + li {
        margin-left: 10px;
      }

      button {
        font-size: 0;
        height: 7px;
        width: 7px;
        padding: 0;
        background-color: rgba($color-grey, 0.5);
        border-radius: 50%;
        border: 0;
        outline: none !important;
      }

      &.slick-active button {
        background-color: $color-orange2;
      }
    }
  }
}

/* #endregion */

@media (min-width: 1220px) {
  .container {
    max-width: 1020px;
  }
}

@media (min-width: 2000px) {
}

@media (max-width: 639.98px) {
  .modal {
    &-qs {
      .modal-dialog {
        max-width: 100%;
        margin: 30px 20px 90px;

        &::before {
          position: fixed;
          content: '';
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #b7b7b7;
          height: 90px;
          z-index: 100;
        }
      }
    }
  }

  .slick-arrow {
    position: fixed;
    top: auto;
    bottom: 15px;
    left: 20px;
    margin-top: 0;
    z-index: 110;
  }

  .slick-next {
    left: auto;
    right: 20px;
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -1rem, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

// Stepper Region
.content {
  &__stepper-block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background-color: $color-orange1;
    margin-top: -2px;
    margin-left: -30px;
    position: fixed;
    top: 55px;
    width: calc(100% - 195px);
    z-index: 1;
    padding: 9px 20px;

    @media screen and (max-width: 1024.98px) {
      width: calc(100% - 56px);
    }
    @media screen and (max-width: 639.98px) {
      width: 100%;
    }
    @media screen and (max-width: 500.98px) {
      top: 38px;
    }
    @media screen and (max-width: 359.98px) {
      top: 53px;
    }

    &--last {
      &:not(.stepper-default) {
        width: calc(100% - 478px);

        @media screen and (max-width: 1199.98px) {
          width: calc(100% - 416px);
        }
        @media screen and (max-width: 1024.98px) {
          width: calc(100% - 276px);
        }
        @media screen and (max-width: 991.98px) {
          width: calc(100% - 56px);
        }
        @media screen and (max-width: 639.98px) {
          width: 100%;
        }
      }
    }

    + div {
      height: 0;
    }
  }

  .stepper {
    &-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      width: 100%;
      max-width: 935px;

      @media (max-width: 639.98px) {
        top: 55px;
      }

      @media (max-width: 575.98px) {
        align-items: flex-start;
      }

      @media (max-width: 500.98px) {
        top: 37px;
      }

      @media (max-width: 359.98px) {
        top: 50px;
      }

      .stepper-item {
        display: inline-flex;
      }

      .stepper-item-complete {
        .stepper-item-inner {
          background-color: transparent;
          color: $color-dark-grey;
        }
      }

      .stepper-item,
      .stepper-item-complete {
        position: relative;
        cursor: pointer;
        z-index: 1;
        padding: 0 10px;

        &:first-of-type {
          padding-right: 20px;
          margin-left: -5px;

          @media (max-width: 767.98px) {
            padding-right: 10px;
          }
          @media (max-width: 500.98px) {
            margin-left: 0;
          }
        }

        &:nth-of-type(3) {
          margin-left: 10px;
          margin-right: 10px;

          @media (max-width: 575.98px) {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .stepper-title {
          position: absolute;
          display: inline-block;
          max-width: 200px;
          transform: translate(-10%, 5px);
          text-align: right;
          margin-left: 10px;
          min-width: fit-content;
          width: fit-content;
          color: rgba($color-white, 0.73);
          font-weight: bold;
          font-size: 16px;
          font-family: 'Muli', sans-serif;
          position: initial;
          transform: translate(-10px, -1px);
          word-break: break-word;
          text-align: center;

          &-active {
            color: $color-grey4;
          }

          @media (max-width: 639.98px) {
            margin-left: 8px;
          }

          @media (max-width: 575.98px) {
            text-align: center;
            margin-left: auto;
            transform: translate(0, -1px);
          }

          @media (max-width: 500.98px) {
            margin-left: 0;
            width: 100%;
            word-wrap: break-word;
            padding-left: 5px;
            padding-right: 5px;
          }

          @media (max-width: 399.98px) {
            font-size: rem-calc(13);
          }
        }

        &-outer {
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 575.98px) {
            display: block;
            text-align: center;
          }

          &:nth-of-type(even) {
            height: 0px;
            border: 0.5px solid rgba($color-white, 0.73);
            width: 15%;

            @media (max-width: 1199.98px) {
              width: 3%;
            }

            @media (max-width: 767.98px) {
              display: none;
            }

            @media (max-width: 639.98px) {
              display: none;
            }
          }

          &:nth-of-type(odd) {
            border: none;

            .stepper {
              &-item {
                &-inner {
                  margin: 0 10px 0 5px;
                  color: $color-grey3;
                  text-align: left;
                  position: initial;
                  font-size: 29px;
                  font-family: 'Rubik', sans-serif;
                  display: inline-flex;

                  @media (max-width: 500.98px) {
                    margin: auto;
                  }
                  @media (max-width: 399.98px) {
                    font-size: 25px;
                  }

                  &-future {
                    background-color: transparent;
                    color: rgba($color-white, 0.64);
                  }

                  &-active {
                    background-color: transparent;
                  }

                  &-completed {
                    color: rgba($color-dark, 0.6);
                    ~ .stepper-title {
                      color: rgba($color-dark, 0.6);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &-blue {
      background-color: #c2dbee;
    }
    &-schedule {
      margin-left: -16px;
      .question-cart-content__back {
        margin-top: 0;
        position: absolute;
        left: 1.5rem;
        // position: relative;
        // left: -5rem;
      }
    }
  }
}
// end of stepper region

// Question Cart region
.question-cart-content {
  width: 100%;
  max-width: 1065px;
  background-color: $color-white;
  // border-bottom: 1px solid rgba(#707070, 0.21);
  padding: 15px 1px 0 1px;
  margin: 0 auto 10px;
  font-family: 'Muli', sans-serif;
  border-radius: 0;
  box-shadow: none;

  &__form-required {
    position: relative;

    &::after {
      display: block;
      content: '*';
      position: absolute;
      bottom: -10px;
      right: -12px;
      font-size: 21px;
      color: #ff0000;
    }
  }

  &__back {
    padding: 0;
    margin-top: 10px;
    font-size: 14px;
    color: $color-dark2;
  }

  .assign-header-title h3 {
    word-break: break-all;
  }

  &__field {
    font-size: 25px;
    font-weight: 600;
    padding: 6px 11px;
    border: 1px solid rgba(#707070, 0.34);
    color: $color-dark1;
    line-height: 31px;
    border-radius: 0;
    height: auto;

    &.error {
      border: 1px solid rgba(#ff0000, 0.34);
    }

    &:focus {
      color: $color-dark1;
    }

    &::after {
      display: block;
      content: '*';
    }
  }

  &__right {
    justify-content: flex-end;
    padding: 12px;

    .total-qn {
      left: auto;
      display: block;
      line-height: 38px;
    }

    &-btn {
      margin-left: 19px;
      display: block;
      padding: 6px 40px;
      font-size: 18px;
      background-color: #ff5b00;
      border-color: rgba(#707070, 0.1);
    }

    .total-qn-number {
      position: relative;
      top: 5px;
      font-size: 30px;
      font-weight: bold;
    }
  }
}

.question-cart-list {
  width: 100%;
  max-width: 1065px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .question-cart-content {
    display: block !important;

    &__form-required {
      padding-right: 10px;

      &::after {
        right: -6px;
      }
    }

    //button assign print
    .assign-print-btn {
      margin-left: 10px;
      margin-bottom: 10px;
    }

    &__right {
      padding-top: 0;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }

  .sidebar {
    &-create-assign {
      top: 37px;
    }
  }
}

@media screen and (max-width: 500px) {
  //
  [class*='icon-'] {
    height: 15px;
    width: 15px;
  }

  .icon-cart-filled {
    top: -1px;
  }
}

@media screen and (max-width: 375px) {
  .question-cart-content {
    &__form-required {
      margin-bottom: 0;
    }
    &__right {
      display: block !important;

      &-btn {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

// Add Instruction Button
.question-add-instruction {
  margin-bottom: 12px;

  .btn {
    font-family: 'Muli', sans-serif;
    display: block;
    width: 100%;
    text-align: center;
    background-color: #fff6ee;
    color: $color-orange2;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 20px;

    &:hover {
      background-color: $color-orange2;
      border-color: #ff5b00;
      color: $color-white;
    }
  }
}

.trash-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.trash-icon-all {
  height: 25px;
  width: 25px;
  margin-top: 10px;
}

.remove-question-btn {
  padding: 0.2rem 1rem;
}
.remove-question-all-btn {
  margin-left: 20px;
  padding: 0.2rem 0;
}
.fraction {
  vertical-align: middle;
  text-align: center;
}
.check-add-score {
  position: unset !important;
  margin-bottom: 10px;
  ~ label {
    position: absolute;
    left: rem-calc(25);
  }
  ~ span {
    border: solid 1px $color-dark;
    left: 0;
    pointer-events: none;
  }
}

// Preview Table
.preview-table {
  border: 1px solid #707070 !important;
  margin-bottom: 0;
  tr {
    &:nth-child(odd) {
      background-color: #fffcf9;
    }
    &:nth-child(even) {
      background-color: #fff3ec;
    }

    th,
    td {
      border-color: #707070;
      text-align: center;
    }
  }

  &__header {
    th {
      font-weight: bold;
      padding: 10px;
      color: $color-black;
      &:first-child {
        width: 24%;
        text-align: left;
      }
    }
  }

  &__content {
    td {
      vertical-align: middle;
      padding: 7px 15px;
      &:first-child {
        width: 24%;
        text-align: left;
        font-size: 0.8125rem;
        color: #0c0c0c;
      }
    }
  }
}
.worksheet-preview {
  &-text {
    width: auto;
    text-align: center;
    transform: translate(35%, 0);
    margin-bottom: 35px;
    padding: 0;
    font-family: 'Muli', sans-serif;
    color: $color-black;

    @media (max-width: 767px) {
      transform: none;
      padding: 0 20px;
    }
    h3 {
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 25px;
    }
    p {
      font-size: 14px;
      text-align: left;
    }
  }
  &-content {
    border-top: 1px solid rgba(#707070, 0.21);
    padding: 30px;
    margin-top: -10px;
    border-radius: 0;
  }
}
.presentation {
  &-standard,
  &-kooquiz {
    padding: 15px 15px;
    border-radius: 10px;
    background-color: $color-body-bg;
    font-family: 'Muli';
    .btn.btn-link.button-help {
      padding-top: 3px;
    }
    .btn-outline-primary {
      background-color: $color-white;
      color: $color-orange6;
      &:hover {
        color: $color-white;
        background-color: $color-orange6;
      }
    }
    h4 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 15px;
    }
    img {
      width: 100%;
    }
    p {
      margin: 0;
      padding: 10px 0;
    }
    .custom-radio:after {
      content: '';
      position: absolute;
    }
    input[type='radio'].presentation-check {
      opacity: 0;
      position: absolute;
      top: -30px;
      left: -30px;
      width: 45px;
      height: 45px;
      cursor: pointer;
      &:hover ~ .custom-radio {
        background-image: url('../img/check.png');
      }
      &:checked ~ .custom-radio {
        background-image: url('../img/check-active.png');
      }
      &:checked ~ .custom-radio:after {
        display: block;
      }
    }
    .custom-radio {
      position: absolute;
      height: 45px;
      width: 45px;
      left: -30px;
      top: -30px;
      border-radius: 50%;
      background-image: url('../img/check.png');
      pointer-events: none;
    }
  }
  &-standard {
    .mb-3 {
      display: inline-block;
      min-width: 170px;
      margin-top: 6px;
      .form-check {
        padding-left: 0;
        .form-check-label {
          margin-top: 0;
        }
        .custom-radio:after {
          content: '';
          position: absolute;
          display: none;
        }
        input[type='radio'] {
          opacity: 1;
          width: 20px;
          height: 20px;
          &:hover ~ .custom-radio {
            background-color: $color-white;
          }
          &:checked ~ .custom-radio {
            background-color: $color-white;
          }
          &:checked ~ .custom-radio:after {
            display: block;
          }
        }
        .custom-radio {
          position: absolute;
          height: 20px;
          width: 20px;
          left: -1px;
          top: 0px;
          border-radius: 50%;
          background-color: $color-white;
          border: solid 1px $color-grey2;
          pointer-events: none;
          &:after {
            left: 4px;
            top: 4px;
            width: 5px;
            height: 5px;
            border: solid $color-orange6;
            border-width: 5px;
            border-radius: 50%;
          }
          ~ .form-label {
            margin-bottom: 0;
            transform: translate(0, -5px);
          }
        }
      }
    }
  }
  &-active {
    background-color: rgba($color-orange6, 0.15);
  }
}

.border {
  &-line {
    border: 0.5px solid $color-grey2;
    margin: 20px -15px;
  }
}

// Assign Sidebar
.save-assign {
  &_sidebar-align {
    overflow: auto;
    max-height: 130px;
    margin-bottom: 5px;
  }
  &__sidebar {
    width: 283px;
    height: 100%;
    background-color: $color-white;
    box-shadow: 0 6px 3px rgba($color-black, 0.16);
    &.sidebar-right {
      overflow: visible;
    }
  }
  &__title {
    text-align: center;
    font-size: 18px;
    font-family: 'Muli', sans-serif;
    line-height: 23px;
    color: #090909;
    margin-bottom: 10px;
    margin-top: 20px;
    text-transform: uppercase;
  }
  &__content-action {
    padding: 0px 25px;
    border-bottom: 1px solid rgba(#707070, 0.24);

    &-item {
      display: flex;
      display: -webkit-flex;
    }

    &-icon {
      position: relative;
      top: 5px;
    }

    &-caption {
      margin-left: 15px;
      margin-bottom: 15px;

      p {
        color: $color-dark2;
        margin-bottom: 2px;
      }

      select {
        font-family: 'Muli', sans-serif;
        border: 1px solid #707070;
        padding: 2px 10px;
      }

      .class-list,
      .group-list {
        margin-bottom: 1rem;

        h5 {
          font-size: rem-calc(14);
          margin-bottom: 2px;
        }
      }
    }

    &-rewards {
      display: flex;
      display: -webkit-flex;
      align-items: flex-end;

      p {
        color: $color-orange2;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }
  &__content-submit {
    padding: 20px 12px;

    .btn {
      border: 1px solid transparent;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 10px;

      &-save-only {
        color: $color-orange2;
        border-color: #a5a5a5;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
          background-color: #a5a5a5;
          color: $color-white;
        }
      }

      &-save-assign {
        color: $color-white;
        border-color: rgba($color-orange0, 0.1);
        background-color: $color-orange2;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
          background-color: $color-orange10;
        }
        @media screen and (min-width: 1220px) {
          margin-right: 0;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      padding: 20px 7px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1199px) {
  .save-assign {
    &__sidebar {
      width: 220px;
    }
    &__title {
      padding-left: 10px;
      padding-right: 10px;
    }
    &__content-action {
      padding-left: 15px;
      padding-right: 15px;

      &-caption {
        margin-left: 10px;
      }
    }
    &__content-submit {
      .btn {
        margin: 0 0 0.75em 0;
        width: 100%;
        display: block;
      }
    }
  }
}

@media screen and (max-width: 991.98px) {
  .save-assign {
    &__sidebar {
      top: 114px;
      right: 15px;
      width: 200px;
    }
    &__title {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .save-assign {
    &__sidebar {
      width: 180px;
    }
    &__content-action {
      padding-left: 10px;
      padding-right: 10px;

      &-caption {
        margin-left: 10px;
      }
    }
    &__content-submit {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media screen and (max-width: 479.98px) {
  .save-assign {
    &__sidebar {
      width: 150px;
    }
    &__content-action {
      padding-bottom: 15px;
      &-caption {
        margin-bottom: 5px;

        p {
          margin-bottom: 0;
        }
      }
      &-icon {
        margin-bottom: 0.5em;
      }
    }
    &__content-submit {
      padding-top: 10px;
      padding-bottom: 10px;

      .btn {
        font-size: 0.8125rem;
      }
    }
  }
}
// end of assign sidebar region

// assign page
.assign {
  &-page {
    width: calc(100% - 248px);
    padding-left: 16px;
    padding-right: 46px;
    margin-left: 0;
    margin-right: auto;

    @media (max-width: 1199.98px) {
      width: calc(100% - 186px);
    }

    @media (max-width: 767.98px) {
      padding-left: 0;
      padding-right: 30px;
    }

    @media (max-width: 479.98px) {
      width: 100%;
      padding-right: 0;
    }

    .question-cart-content__back {
      width: fit-content;
      margin-left: 10px;
    }
  }
  &-header {
    &-title {
      font-family: 'Muli', sans-serif;
      h3 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    &-subtitle {
      div {
        font-size: 13px;
        padding-bottom: 15px;
        span {
          font-size: 17px;
          color: $color-orange2;
          font-weight: bold;
        }
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
  &-content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: -30px auto 0;
    width: 100%;
    max-width: 1065px;

    .question {
      &-header {
        font-family: 'Muli', sans-serif;
        h4 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0;
        }
        p {
          font-size: 16px;
        }
        &-title {
          font-size: 20px;
          margin-top: -5px;
          span {
            font-weight: bold;
          }
        }
        &-meta {
          font-size: 14px;
          span {
            color: $color-orange2;
            font-weight: bold;
          }
        }
      }
    }
    .media {
      font-family: 'Muli', sans-serif;
      &-body {
        p {
          font-size: 16px;
        }
        input[type='text'] {
          width: rem-calc(67);
          height: rem-calc(24);
          background-color: $color-body-bg;
          border: none;
        }
      }
      &-right {
        img {
          max-width: 100%;
        }
      }
    }
  }
  &-print-btn {
    margin-right: 25px;
    border-radius: 5px;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
}
@media (max-width: 1024px) {
  .content
    .content__stepper-block--last
    ~ div
    .assign-page
    .question-cart-content {
    margin-left: 0px;
  }
  .box-white.question-card-content {
    width: auto !important;
  }
}
@media (max-width: 991px) {
  .content.content-sidebar-small {
    min-height: calc(100vh);
  }
}
@media (max-width: 768px) {
  .worksheet-preview-content {
    @media (max-width: 767px) {
      .row .col-md-6:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .edit-worksheet {
    .question-cart-content {
      margin-left: 0;
    }
    .check-add-score {
      left: 100px;
      ~ .custom-checkbox {
        left: 100px;
        @media (max-width: 991px) {
          left: 120px;
        }
        @media (max-width: 639px) {
          left: 40px;
        }
      }
      ~ label {
        left: 130px;
        @media (max-width: 991px) {
          left: 150px;
        }
        @media (max-width: 639px) {
          left: 70px;
        }
      }
    }
    .box-white-wrap {
      .question-card.create-assign {
        padding-left: 10px;
      }
    }
  }
}
@media (max-width: 639px) {
  .save-assign__sidebar {
    width: 200px;
    top: 113px;
    right: 20px;
  }
}
@media (max-width: 575px) {
  .save-assign {
    &__sidebar {
      top: 135px;
    }
  }
}
@media (max-width: 500px) {
  .save-assign {
    &__sidebar {
      top: 140px;
    }
  }
}
@media (max-width: 479px) {
  .assign-header-subtitle div {
    font-size: 11px;
  }
  .assign-header-subtitle div span {
    font-size: 10px;
  }
  .save-assign {
    &__sidebar {
      position: relative;
      top: 0;
      right: 0;
      height: max-content;
      width: 100%;
      padding-top: 0.1rem;
    }
  }
}
@media (max-width: 399px) {
  .assign-page {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.modal-dialog {
  min-width: 63%;
  .modal-content {
    border-radius: 0;
  }
}
.assign-modal {
  .assign {
    &-modal {
      font-family: 'Muli';
      &-header {
        background-color: $color-body-bg;
        margin: 0 -10px;
        padding: 1rem 1.2rem 0.7rem 1.2rem;
        color: $color-black;
        font-size: 15px;
        font-weight: bold;
        border-bottom: solid 0.5px rgba($color-grey, 0.6);
        .form-check {
          padding: 0 1rem;
          flex: none;
          [type='radio']:checked + label:after {
            content: '';
            width: 11px;
            height: 11px;
            background-color: $color-orange;
            position: absolute;
            top: 3.8px;
            left: -16.3px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            border: solid 1px $color-white;
          }
          [type='radio']:not(:checked) + label:after {
            content: '';
            width: 10px;
            height: 10px;
            background-color: $color-white;
            position: absolute;
            top: 4.8px;
            left: -15.5px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
          ~ span {
            color: rgba($color-grey, 0.5);
          }
          &-radio ~ label {
            position: relative;
          }
          @media (max-width: 1279px) {
            width: 100%;
            display: contents;
            input[type='radio'] {
              margin: 4px;
            }
            + span {
              margin: 0 5px;
            }
            [type='radio']:not(:checked) + label:after {
              top: 5px;
              left: -15.9px;
            }
            [type='radio']:checked + label:after {
              top: 4.2px;
              left: -16.3px;
            }
          }
        }
      }
      &-body {
        margin-left: 1.7rem;
        p {
          margin: 10px 0 10px 0;
        }
        .form-group {
          margin-bottom: 0.5rem;
          select {
            display: inline-block;
            max-width: 9rem;
            border-radius: 0;
            background-color: white;
            font-size: 14px;
            padding: 0 0 0 10px;
            height: 25px;
            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: linear-gradient(
                60deg,
                transparent 50%,
                $color-black 50%
              ),
              linear-gradient(120deg, $color-black 50%, transparent 50%),
              linear-gradient(to right, #fff, #fff);
            background-position: calc(100% - 15px) 0.45em,
              calc(100% - 8.5px) 0.45em, calc(100% - 2.5em) 0.5em;
            background-size: 7px 10px, 7px 10px, 20px 2.5em;
            background-repeat: no-repeat;
          }
        }
        .assign-form {
          display: flex;
          .assign-modal-textarea,
          .assign-modal-textarea--selected {
            width: 40%;
            padding: 0;
            min-height: 200px;
            margin-bottom: 3rem;
            border-radius: 0;
            option {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 0.1775rem 0.755rem;
              &:checked {
                background: $color-orange2
                  linear-gradient(0deg, $color-orange2 0%, $color-orange2 100%) !important;
              }
            }
          }
          .assign-modal-textarea--selected {
            background-color: rgba($color-orange2, 0.15);
          }
          &-button {
            width: 20%;
            .btn {
              display: block;
              width: 85%;
              margin: 0 auto;
              border: none;
              overflow-wrap: anywhere;
              &-primary {
                background-color: $color-orange2;
                margin-top: 2rem;
                margin-bottom: 10px;
              }
              &-info {
                background-color: #457688;
              }
            }
          }
        }
        .row.form-group {
          margin-left: -8px;
          display: inline-block;
          min-width: 50%;
          @media (max-width: 692px) {
            min-width: 100%;
          }
          .form-check {
            display: inline-block;
            [type='checkbox'] {
              opacity: 0;
              position: absolute;
              & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
                margin-left: -28px;
              }
              & + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: white;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
                border: solid 1px rgba($color-grey, 0.5);
              }
              &:hover + label:before {
                background: $color-orange2;
              }
              &:checked + label:before {
                background: $color-orange2;
              }
              &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
              }
              &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
              }
              &:checked + label:after {
                content: '';
                position: absolute;
                left: 2.5px;
                top: 10px;
                background: white;
                width: 2px;
                height: 2px;
                box-shadow: -2px 0 0 white, 2px 0 0 white, 4px 0px 0 white,
                  6px 0px 0 white, 6px -2px 0 white, 6px -4px 0 white,
                  6px -6px 0 white, 6px -8px 0 white, 6px -10px 0 white,
                  6px -12px 0 white, 6px -14px 0 white;
                transform: rotate(45deg);
              }
              &:checked + label {
                font-weight: normal;
              }
            }
          }
        }
      }
      &-btn {
        position: absolute;
        bottom: 2.5rem;
        width: calc(100% - 20px);
      }
      &-form {
        border-right: solid 0.5px rgba($color-grey, 0.6);
        @media (max-width: 1300px) {
          max-width: 100%;
          padding-bottom: 20px;
          border-bottom: solid 0.15px $color-grey;
          border-right: none;
        }
      }
      &-info {
        position: relative;
        margin-top: 2.5rem;
        min-height: 70vh;
        padding-bottom: 6rem;
        h3 {
          font-size: 16px;
          font-weight: bold;
          color: $color-black;
        }
        .class-list,
        .group-list {
          margin-bottom: 10px;

          h5 {
            font-size: 14px;
            margin-bottom: 0;
          }
          p {
            color: $color-orange2;
            margin-bottom: 0;
          }
        }
        .btn-secondary {
          background-color: rgba($color-grey, 0.3);
          border: rgba($color-grey, 0.4);
        }
        @media (max-width: 1300px) {
          max-width: 100%;
          margin-top: 1.5rem;
          margin-left: 1.5rem;
          min-height: 1vh;
          padding-bottom: 2rem;
          .assign-modal-btn {
            position: initial;
            width: calc(95% - 20px);
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .d-flex.assign-modal {
    display: block !important;
  }
}
.students-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  width: 100%;
  max-width: 216px;

  h5 {
    white-space: normal;
    word-wrap: normal;
  }

  p {
    text-transform: capitalize;
    word-wrap: normal;
    white-space: normal;

    .btn-selected-student {
      margin-left: 2px;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: relative;
      padding: 0;

      &::after {
        border: 0;
        background-image: url('../img/icon-cross.svg');
        width: 16px;
        height: 16px;
        background-size: cover;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        top: -8px;
        content: '';
      }

      &:hover::after {
        background-image: url('../img/icon-cross_hover.svg');
      }
    }
  }
}

.saved-worksheet {
  h5 {
    width: 100%;
    margin: rem-calc(10) auto rem-calc(30) auto;
    text-align: center;
  }
  .btn-private {
    padding-bottom: 3px;
    padding-top: 3px;
    margin-left: 15px;
    float: right;
    border: solid 1px rgba($color-grey, 0.15);
    color: $color-orange2;
    &-create {
      float: right;
      margin-top: -55px;
    }
  }
  .list-group {
    &-item {
      vertical-align: center;
      font-size: 18px;
      .list-group-initial {
        min-width: rem-calc(36);
        display: inline-block;
        padding-top: 5px;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 50%;
        margin-right: 12px;
        color: $color-white;
        background-color: $color-orange2;
      }
    }
  }
}
.custom-popups {
  width: 100%;
  .modal {
    &-content {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      border: solid 1px $color-grey7;
      .modal-body {
        padding: 25px 2rem 5px 2rem;
        h4 {
          font-size: 20px;
          text-align: center;
          margin-bottom: 10px;
          color: $color-orange2;
        }
        p {
          font-size: 18px;
        }
        .btn-primary,
        .btn-outline-light {
          min-width: 170px;
          margin: 0 auto;
          margin-bottom: 1.7rem;
          margin-top: 0.5rem;
        }
        .btn-primary {
          margin-right: 10px;
        }
        .btn-outline-light {
          color: $color-dark-grey;
          border-color: $color-grey7;
          margin-left: 10px;
        }
        .justify-content-center {
          text-align: center;
          &.align-items-center {
            margin-bottom: 10px;
          }
        }
        ul {
          padding-left: 15px;
          .custom-popups-list {
            font-size: 18px;
            list-style: none;
            margin-bottom: 20px;
            &::before {
              content: '\2022';
              color: $color-orange2;
              font-weight: bold;
              display: inline-block;
              width: 0.9em;
              margin-left: -1em;
              margin-bottom: 0.1em;
            }
            .btn {
              color: $color-grey;
              font-size: 16px;
              padding: 0;
              i {
                margin-right: 4px;
              }
            }
          }
        }
        .custom-popups__assign-final {
          p {
            font-size: 16px;
            &:nth-of-type(2) {
              font-size: 12px;
              color: $color-orange2;
              margin-bottom: 1rem;
            }
          }
          + .justify-content-center .btn.btn-primary {
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
  &__assign-success {
    p:nth-of-type(2) {
      font-weight: bold;
      margin-bottom: 5px;
    }
    + .justify-content-center .btn.btn-primary {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
  &__input {
    width: 100%;
    padding: 6px;
    color: $color-grey;
    border: solid 1px $color-grey2;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  &__saved-only {
    li {
      font-size: 18px;
      .btn-link {
        font-size: 15px;
        color: $color-grey;
        display: block;
      }
    }
    p:last-of-type {
      margin-bottom: 1.2rem;
    }
    ~ .justify-content-center {
      padding-bottom: 1.2rem;
    }
  }
}
input[type='checkbox'].form-check-input {
  opacity: 0;

  .form-check-visible & {
    opacity: 1;
  }
}
.forbidden-page {
  padding-top: 5rem;
  text-align: center;
  justify-content: center;

  &__icon {
    min-width: 10%;
  }
  &__title {
    font-size: 25px;
    margin-top: 10px;
  }
  &__desc {
    font-size: 18px;
    max-width: 30rem;
    margin: 0 auto 10px auto;
  }
}

.content-data {
  position: relative;
  &.on-loading {
    background-color: rgba($color-grey, 0.15);
  }

  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  }
}
.import-question-list {
  padding-left: 220px;
  @media (max-width: 1024px) {
    padding-left: 60px;
  }
  .box-white {
    .box-white-body {
      padding-bottom: 0;
    }
  }
  &__input--check {
    margin-left: 1rem;
  }
  .content-data {
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    .table {
      background-color: $color-white;
      margin-bottom: 0;
      &-bordered {
        border: none;
      }
      tr {
        th {
          padding-top: 7px;
          border-color: $color-orange11;
          &:nth-of-type(3) {
            text-align: right;
          }
          &:first-of-type {
            padding-left: 2rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }
        td {
          border: none;
          &:nth-of-type(3) {
            text-align: right;
          }
          &:first-of-type {
            padding-left: 2rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }
      }
      tbody tr:nth-of-type(odd) {
        background-color: rgba($color-orange6, 0.2);
      }
      .search-questionid__input--check {
        position: relative;
        cursor: pointer;
        padding: 0;
        .form-check-input {
          &:after {
            position: absolute;
            pointer-events: none;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background: white;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            border: solid 1px rgba($color-grey, 0.5);
          }
          &:checked:before {
            content: '';
            position: absolute;
            right: 15.3px;
            top: 14px;
            background: $color-orange6;
            width: 2px;
            height: 2px;
            box-shadow: -2px 0 0 $color-orange6, 2px 0 0 $color-orange6,
              4px 0px 0 $color-orange6, 6px 0px 0 $color-orange6,
              6px -2px 0 $color-orange6, 6px -4px 0 $color-orange6,
              6px -6px 0 $color-orange6, 6px -8px 0 $color-orange6,
              6px -10px 0 $color-orange6, 6px -12px 0 $color-orange6,
              6px -14px 0 $color-orange6;
            transform: rotate(45deg);
            z-index: 1;
          }
          &:checked {
            background: rgba(#fff, 0);
          }
          &:hover:after {
            background: $color-orange2;
          }
        }
      }
      thead {
        .search-questionid__input--check {
          .form-check-input {
            &:checked:before {
              top: 9px;
            }
            ~ .form-check-label {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  &__input--check {
    margin-bottom: 5px;
    .form-check-input {
      position: relative;
      cursor: pointer;
      padding: 0;
      margin-right: 5px;
      margin-left: -15px;
      margin-top: 0.8rem;
      &:after {
        position: absolute;
        pointer-events: none;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: white;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        border: solid 1px rgba($color-grey, 0.5);
      }
      &:checked:before {
        content: '';
        position: absolute;
        right: 15.3px;
        top: 10px;
        background: $color-orange6;
        width: 2px;
        height: 2px;
        box-shadow: -2px 0 0 $color-orange6, 2px 0 0 $color-orange6,
          4px 0px 0 $color-orange6, 6px 0px 0 $color-orange6,
          6px -2px 0 $color-orange6, 6px -4px 0 $color-orange6,
          6px -6px 0 $color-orange6, 6px -8px 0 $color-orange6,
          6px -10px 0 $color-orange6, 6px -12px 0 $color-orange6,
          6px -14px 0 $color-orange6;
        transform: rotate(45deg);
        z-index: 1;
      }
      &:checked {
        background: rgba(#fff, 0);
      }
      &:hover:after {
        background: $color-orange2;
      }
      ~ .form-check-label {
        position: relative;
        bottom: 0.15rem;
      }
    }
  }
  &__input--text {
    border: solid 0.5px $color-orange6;
  }
  &__btn--add:disabled {
    background-color: rgba($color-orange10, 0.65);
    border: none;
    opacity: 1;
  }
  &__label--info {
    padding: 0.9rem;
  }
}
.schedule-content {
  margin-top: 5rem;
  .border-line {
    margin-left: -100rem;
    margin-right: -12rem;
    overflow: hidden;
  }
  &-title {
    h1 {
      font-size: 20px;
      margin-top: 5px;
      font-weight: bold;
      color: $color-black;
    }
  }
  &-nav-btn {
    .btn {
      margin: 0.4rem;
      &-primary {
        width: 8rem;
      }
      &-outline-primary {
        border-color: #909090;
      }
    }
  }
  .save-assign__content-action-caption {
    margin-bottom: 2rem;
    p {
      color: $color-black;
      font-weight: bold;
    }
    .react-bootstrap-daterangepicker-container,
    .daterangepickercontainer {
      a {
        color: $color-empty-cart-text;
        text-decoration-line: underline;
      }
    }
  }
  .save-assign__content-action-rewards {
    p {
      color: $color-black;
      font-weight: 200;
    }
    .schedule-option {
      &-arrow {
        position: relative;
        &:after {
          content: '';
          width: 0;
          height: 0;
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          position: absolute;
          right: 10px;
          top: 7px;
          pointer-events: none;
        }
        &-visibility:after {
          top: 9px;
        }
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
        }
      }
      &-visibility {
        width: 15rem;
        padding: 5px;
      }
      &-rewards {
        width: 4rem;
      }
    }
  }
  .form-check {
    .form-label {
      font-size: 16px;
    }
  }
  .assign-modal .assign-modal-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-top: 0.5rem;
    &:before {
      content: url('../img/icon-peoples.svg');
    }
    .form-check {
      &:first-of-type {
        padding-left: 5px;
      }
      [type='radio']:checked + label:after {
        top: 4.5px;
        left: -15.9px;
      }
    }
  }
  .assign-modal .assign-modal-body {
    p {
      font-size: 16px;
    }
    .form-group {
      position: relative;
      display: inline;
      margin-right: 1rem;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: absolute;
        right: 10px;
        top: 3px;
        pointer-events: none;
      }
      &.row {
        display: inline-block;
        min-width: 33%;
        &:after {
          display: none;
        }
      }
      select {
        background-image: none;
        max-width: 11rem;
      }
    }
  }
  .schedule-checkbox {
    &-group {
      margin-top: 1.5rem;
    }
  }
  .assign-modal .assign-modal-info.assign-class {
    margin-top: 1.5rem;
    padding-left: 2.5rem;
  }
}
.plain-text {
  &__label {
    max-width: 10.625rem;
  }
}
.panel-with-icon {
  .panel-schedule {
    &-time {
      &:before {
        content: url('../img/icon-calendar-orange.svg');
        position: absolute;
        left: -23px;
        top: -2px;
      }
    }
    &-assign-to {
      &:before {
        content: url('../img/icon-peoples.svg');
        position: absolute;
        left: -20px;
        top: 1px;
      }
      .plain-text__value {
        padding-left: 5px;
      }
    }
    &-rewards {
      &:before {
        content: url('../img/icon-rewards-orange.svg');
        position: absolute;
        left: -13px;
        top: -2px;
      }
    }
    &-time,
    &-rewards,
    &-assign-to {
      position: relative;
      margin-bottom: 2rem;
      .panel-header {
        font-size: 16px;
        font-weight: bold;
      }
      .form-group.row {
        margin-bottom: 0;
      }
    }
  }
}
// this for question table (from xml parser)
.table-container {
  overflow-x: auto;
  .basic-table {
    border-collapse: collapse;
    border-bottom: 1px solid;
    width: 600px;
    table-layout: fixed;
    thead {
      td {
        font-weight: bold;
      }
    }
    td,
    th {
      text-align: center;
      border: 1px solid black;
      font-style: normal;
      vertical-align: middle;
      word-break: break-word;
      &:first-child {
        width: 45px;
      }
    }
    td {
      font-weight: normal;
    }
  }
}
// this for question table (from xml parser) end
