.popover-tooltip {
  max-width: 300px;
  max-height: 170px;
  text-align: left;
  overflow: auto;
  color: #fff;
  padding: 5px 10px;
  background: #000;
}
.tooltip-inner {
  border: none !important;
}
