// Import Global Styles
@import '../../assets/scss/Colors.scss';
@import '../../assets/scss/Fonts.scss';

.saved-worksheet {
  &__header {
    margin-bottom: 1rem;
    .col-sm-12 {
      text-align: center;
      h5 {
        display: inline-block;
        margin-left: 1rem;
        color: $color-dark2;
      }
    }
    @media (max-width: 767.98px) {
      margin-bottom: 0.5em;
    }
  }

  &__icon {
    display: inline-block;
    position: relative;
    top: -3px;

    &--schedule {
      height: 17px;
      width: 17px;
      margin-right: 10px;
      @media (max-width: 1024px) {
        margin-right: 3px;
      }
    }

    &--assigned {
      margin-right: 6px;
    }
  }

  &__type {
    margin-right: 1rem;
  }
}
