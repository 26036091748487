// Icon List
[class*='icon-'] {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  width: 20px;
  height: 20px;
}

.icon {
  &-list {
    background-image: url('../img/icon-list.svg');

    a:hover &,
    .active & {
      background-image: url('../img/icon-list_hover.svg');
    }
  }

  &-cart {
    position: relative;
    top: -1px;
    width: 26px;
    height: 26px;
    background-image: url('../img/icon-cart.svg');
  }

  &-cart-filled {
    position: relative;
    top: -1px;
    width: 26px;
    height: 26px;
    background-image: url('../img/icon-cart-filled.svg');
  }

  &-switch {
    background-image: url('../img/icon-switch.svg');
    margin-right: 20px;
  }

  &-profile {
    background-image: url('../img/icon-profile.svg');
    margin-right: 20px;
  }

  &-global {
    background-image: url('../img/icon-global.svg');
    margin-right: 20px;
  }

  &-logout {
    background-image: url('../img/icon-logout.svg');
    margin-right: 20px;
  }

  &-bell {
    width: 17px;
    height: 17px;
    background-image: url('../img/icon-bell.svg');
  }

  &-notification {
    background-image: url('../img/icon-notification.svg');

    a:hover &,
    .active & {
      background-image: url('../img/icon-notification_hover.svg');
    }
  }

  &-report {
    background-image: url('../img/icon-report.svg');

    a:hover &,
    .active & {
      background-image: url('../img/icon-report-active.svg');
    }
  }

  &-track-assignment {
    background-image: url('../img/icon-track-assignment.svg');

    a:hover &,
    .active & {
      background-image: url('../img/icon-track-assignment-active.svg');
    }
  }

  &-browse {
    background-image: url('../img/icon-browse.svg');

    a:hover &,
    .active & {
      background-image: url('../img/icon-browse-active.svg');
    }
  }

  &-create {
    background-image: url('../img/icon-create.svg');
    width: 19px;

    a:hover &,
    .active & {
      background-image: url('../img/icon-create-active.svg');
    }
  }

  &-organization {
    background-image: url('../img/icon-organization.svg');
    width: 16px;
    height: 17px;

    a:hover &,
    .active & {
      background-image: url('../img/icon-organization_hover.svg');
    }
  }

  &-package {
    background-image: url('../img/icon-package.svg');
    width: 18px;

    a:hover &,
    .active & {
      background-image: url('../img/icon-package_hover.svg');
    }
  }

  &-counter {
    background-image: url('../img/icon-counter.svg');
    height: 23px;
    width: 19px;

    a:hover &,
    .active & {
      background-image: url('../img/icon-counter_hover.svg');
    }
  }

  &-heart {
    background-image: url('../img/icon-heart.svg');
    width: 16px;
    height: 14px;

    a:hover & {
      background-image: url('../img/icon-heart_hover.svg');
    }

    .active & {
      background-image: url('../img/icon-heart_active.svg') !important;
    }
  }

  &-flag {
    background-image: url('../img/icon-flag.svg');
    width: 11px;
    height: 13px;

    a:hover & {
      background-image: url('../img/icon-flag_hover.svg');
    }

    .active & {
      background-image: url('../img/icon-flag_active.svg') !important;
    }
  }

  &-refresh {
    background-image: url('../img/icon-refresh.svg');
    width: 16px;
    height: 17px;

    a:hover & {
      background-image: url('../img/icon-refresh-orange.svg');
    }
  }

  &-refresh-orange {
    background-image: url('../img/icon-refresh-orange.svg');
    width: 16px;
    height: 17px;
  }

  &-cross {
    background-image: url('../img/icon-cross.svg');
    height: 15px;
    width: 15px;

    a:hover &,
    .active > &,
    .btn:hover & {
      background-image: url('../img/icon-cross_hover.svg');
    }
  }

  &-manage-class {
    background-image: url('../img/icon-manage-class.svg');
    width: 22px;
    height: 22px;

    a:hover &,
    .active & {
      background-image: url('../img/icon-manage-class-active.svg');
    }
  }

  &-help {
    background-image: url('../img/icon-help.svg');
    width: 19px;
    height: 19px;

    a:hover &,
    .active & {
      background-image: url('../img/icon-help-active.svg');
    }
  }

  &-chilli {
    background-image: url('../img/icon-chilli-inactive.svg');
    width: 16px;
    height: 17px;
    margin-right: 0 !important;

    &.active {
      background-image: url('../img/icon-chilli-active.svg');
    }
  }
  &-plus:hover {
    fill: $color-white !important;
  }
  &-table-check {
    background-image: url('../img/icon-check-orange.svg');
    width: 26px;
    height: 20px;
  }
  &-table-line {
    background-image: url('../img/icon-line-brown.svg');
    width: 24px;
    height: 5px;
  }
  &-help {
    background-color: $color-orange6;
    border-radius: 50%;
    padding: 1.5px 6.5px;
    color: $color-white;
    font-weight: bold;
    margin-right: 10px;
  }
  &-class-group {
    background-image: url('../img/icon-class-group.svg');
    width: 14px;
    height: 16px;
  }
  &-class-start {
    background-image: url('../img/icon-class-start.svg');
    width: 18px;
    height: 19px;
  }
  &-class-start-orange {
    background-image: url('../img/icon-calendar-orange.svg');
    width: 18px;
    height: 19px;
  }
  &-class-reward {
    background-image: url('../img/icon-class-reward.svg');
    width: 17px;
    height: 22px;
  }
  &-class-reward-orange {
    background-image: url('../img/icon-rewards-orange.svg');
    width: 17px;
    height: 22px;
  }
  &-saved {
    background-image: url('../img/icon-saved.svg');
    width: 19px;
    margin-right: 5px;
  }
  &-document {
    background-image: url('../img/icon-document.svg');
    width: 19px;
    height: 23px;
  }
  &-presentation {
    background-image: url('../img/icon-presentation.svg');
    width: 24px;
    height: 28px;
  }
  &-pdf {
    background-image: url('../img/icon-pdf.svg');
    width: 25px;
    height: 18px;
  }
  &-pencil {
    background-image: url('../img/icon-pencil.svg');
    width: 20px;
    height: 28px;
  }
  &-print {
    background-image: url('../img/print-icon.svg');
    width: 20px;
    height: 21px;
  }
  &-share {
    background-image: url('../img/icon-share.svg');
    width: 24px;
    height: 20px;
  }
  &-trash {
    background-image: url('../img/icon-trash.svg');
    width: 20px;
    height: 23px;
  }
  &-saved-worksheet {
    background-image: url('../img/icon-new-saved-worksheet.svg');
    width: 25px;
    height: 20px;
  }
  &-saved-worksheet-grey {
    background-image: url('../img/icon-saved-worksheet-grey.svg');
    width: 25px;
    height: 20px;
  }
  &-eye {
    background-image: url('../img/icon-eye.svg');
    width: 18px;
  }
  &-peoples {
    background-image: url('../img/icon-peoples.svg');
    width: 18px;
  }
}
