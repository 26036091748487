// Import Global Styles
@import 'assets/scss/Colors.scss';
@import 'assets/scss/Fonts.scss';

.saved-worksheet {
  &__content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3px;
    width: 100%;
    padding: 0 10px;
    @media (max-width: 767.98px) {
      display: none !important;
    }

    p {
      font-size: 12px;
      opacity: 0.52;
      font-weight: 500;
      color: $color-grey;
    }
  }
}

.saved-worksheet-header {
  &--wtype {
    flex: 0 0 3.2%;
    max-width: 3.2%;
    @media (max-width: 1365.98px) {
      flex: 0 0 4%;
      max-width: 4%;
    }
    @media (max-width: 1199.98px) {
      flex: 0 0 4.6%;
      max-width: 4.6%;
    }
  }

  &--wtitle {
    flex: 0 0 55.2%;
    max-width: 55.2%;
    @media (max-width: 1539.98px) {
      flex: 0 0 49.2%;
      max-width: 49.2%;
    }
    @media (max-width: 1365.98px) {
      flex: 0 0 43.6%;
      max-width: 43.6%;
    }
    @media (max-width: 1199.98px) {
      flex: 0 0 41.6%;
      max-width: 41.6%;
    }
    @media (max-width: 991.98px) {
      flex: 0 0 35.6%;
      max-width: 35.6%;
    }
  }

  &--wdate {
    flex: 0 0 7.8%;
    max-width: 7.8%;
    margin-left: 3rem;
    @media (max-width: 1365.98px) {
      flex: 0 0 8%;
      max-width: 8%;
    }
    @media (max-width: 1199.98px) {
      white-space: break-spaces;
      flex: 0 0 9%;
      max-width: 9%;
    }
    @media (max-width: 991.98px) {
      flex: 0 0 13%;
      max-width: 13%;
    }
  }

  &--wpreview {
    flex: 0 0 6.6%;
    width: 6.6%;
    @media (max-width: 1539.98px) {
      flex: 0 0 10.6%;
      width: 10.6%;
    }
  }
}
