@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('../fonts/CALIBRI.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('../fonts/CALIBRIB.TTF') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('../fonts/CALIBRII.TTF') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('../fonts/CALIBRIL.TTF') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('../fonts/CALIBRILI.TTF') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('../fonts/CALIBRIZ.TTF') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url('../fonts/Helvetica.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('../fonts/Muli.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('MuliB'), url('../fonts/MuliB.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Muli';
  src: local('MuliS'), url('../fonts/MuliS.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url('../fonts/Rubik.ttf') format('truetype');
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Regular'),
    url('../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Bold'), url('../fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-SemiBold'),
    url('../fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Linotte Regular';
  src: url('../fonts/linotte.woff2') format('woff2'),
    url('../fonts/linotte.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Linotte Semibold';
  src: url('../fonts/linotte-semibold.woff2') format('woff2'),
    url('../fonts/linotte-semibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('../fonts/Century-Gothic.ttf') format('truetype');
}

@font-face {
  font-family: 'Linotte Light';
  src: url('../fonts/linotte-light.woff2') format('woff2'),
    url('../fonts/linotte-light.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Ichlthni';
  src: url('../fonts/Ichlthni.ttf') format('truetype');
  font-display: swap;
  font-style: italic;
}