// Import Global Styles
@import 'assets/scss/Colors.scss';
@import 'assets/scss/Fonts.scss';

.saved-worksheet {
  &__content-item {
    margin-bottom: 10px;
    background-color: $color-white;
    box-shadow: 0px 2px 2px rgba($color-black, 0.07);
    border: 1px solid $color-grey8;
  }

  &__item-row {
    display: table;
    padding: 0 10px;
    width: 100%;

    @media (max-width: 767.98px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &--bordered {
      border-bottom: 1px solid $color-grey8;
    }
  }

  &__box {
    margin-bottom: 10px;
    background-color: $color-white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
    padding: 10px 0;
  }

  &-attachment {
    border-top: 1px solid $color-grey8;
    width: 100%;
    margin-top: 5px;
    height: 30px;

    &__link {
      border-radius: 30px;
      border: 1px solid $color-grey9;
      padding: 3px 12px 3px 4px;
      top: 10px;
      color: black;
      font-size: 12px;
      position: relative;
      margin-left: 2rem;

      i.icon-pdf {
        margin: 0 5px;
        margin-top: -1px;
      }
    }
  }

  &__end-text {
    color: $color-grey;
    opacity: 0.52;
    font-size: 13px;
    margin-top: 13px;
  }
}

.saved-worksheet-type {
  width: 3.2%;
  display: table-cell;
  vertical-align: middle;
  @media (max-width: 1365.98px) {
    width: 3.4%;
  }
  @media (max-width: 1199.98px) {
    width: 4%;
  }
  @media (max-width: 991.98px) {
    width: 5%;
  }
  @media (max-width: 767.98px) {
    display: block;
    width: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media (max-width: 399.98px) {
    width: 30px;
  }
}

.saved-worksheet-title {
  width: 55.2%;
  display: table-cell;
  vertical-align: middle;
  color: $color-dark1;
  border-right: 1px solid rgba($color-orange0, 0.2);
  @media (max-width: 1539.98px) {
    width: 49.2%;
  }
  @media (max-width: 1365.98px) {
    width: 44.2%;
    padding: 0 10px;
  }
  @media (max-width: 1199.98px) {
    width: 42%;
    padding: 0 6px;
  }
  @media (max-width: 991.98px) {
    width: 35%;
  }
  @media (max-width: 767.98px) {
    display: block;
    width: 86%;
    border-right: none;
    padding: 10px 0;
  }
  @media (max-width: 399.98px) {
  }
}

.saved-worksheet-date {
  text-align: center;
  width: 7.8%;
  display: table-cell;
  vertical-align: middle;
  border-right: 1px solid rgba($color-orange0, 0.2);
  @media (max-width: 1199.98px) {
    width: 9%;
  }
  @media (max-width: 991.98px) {
    width: 13%;
  }
  @media (max-width: 767.98px) {
    display: block;
    text-align: left;
    width: 100%;
    flex: none;
    border-right: none;
    padding-left: 2.5rem;
  }
  @media (max-width: 399.98px) {
    padding-left: 30px;
  }

  p {
    font-size: 12px;
    line-height: 2rem;
    color: $color-dark1;
  }
}

.saved-worksheet-preview {
  text-align: center;
  width: 6.6%;
  display: table-cell;
  vertical-align: middle;
  border-right: 1px solid rgba($color-orange0, 0.2);
  @media (max-width: 1199.98px) {
    width: 7%;
  }

  @media (max-width: 767.98px) {
    display: block;
    width: 100%;
    padding-left: 2.5rem;
    text-align: left;
    border-right: none;
  }
  @media (max-width: 399.98px) {
    padding-left: 30px;
  }

  i {
    &.icon-document {
      width: 24px;
      height: 27px;
      margin-top: 8px;
      @media (max-width: 991.98px) {
        width: 19px;
        height: 23px;
        background-size: cover;
        background-position: 2px 2px;
        margin-top: 0;
      }
    }

    &.icon-presentation {
      width: 24px;
      height: 28px;
      margin-top: 6px;
    }
  }
}

.saved-worksheet-presentation {
  width: 27.2%;
  display: table-cell;
  vertical-align: middle;
  padding: 8px 0;
  @media (max-width: 1539.98px) {
    width: 33.6%;
  }
  @media (max-width: 1365.98px) {
    width: 39.6%;
  }
  @media (max-width: 1199.98px) {
    width: 38%;
  }
  @media (max-width: 991.98px) {
    width: 40%;
  }
  @media (max-width: 767.98px) {
    display: block;
    width: 100%;
  }

  &__data-action {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;

    @media (max-width: 1199px) {
      padding-left: 0;
    }
    @media (max-width: 1024px) {
      padding-left: 10px;
    }
    @media (max-width: 767px) {
      padding-left: 2.8em;
    }
    @media (max-width: 479px) {
      flex-wrap: wrap;
    }
    @media (max-width: 399px) {
      padding-left: 30px;
    }

    .btn {
      &.btn-outline-secondary {
        color: $color-orange2;
        border-color: #d5d5d5;
        margin: 0 9px;
        padding: 4px 14px 4px 10px;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active {
          border-color: $color-orange2;
          background-color: $color-white;
        }

        @media (max-width: 1199px) {
          margin: 0 5px;
        }
        @media (max-width: 1024px) {
          margin: 0 8px;
        }
        @media (max-width: 991px) {
          margin: 0 4px;
        }
        @media (max-width: 479px) {
          margin-bottom: 0.5em;
        }
      }

      //button assign
      &.btn-saved-assign {
        color: $color-white;
        border-color: $color-orange15;
        background-color: $color-orange2;
        padding: 4px 22px;
        margin-right: 0;
        &:hover,
        &:focus,
        &:active {
          color: $color-white;
          background-color: $color-orange16;
        }
        @media (max-width: 1199px) {
          padding: 4px 14px;
        }
        @media (max-width: 1024px) {
          padding: 4px 22px;
        }
        @media (max-width: 374px) {
          padding: 0.375rem 0.75rem;
        }
      }
    }
  }
}

.saved-worksheet-assignments {
  width: 100%;
  padding: 8px 30px;

  a {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__link {
    position: relative;
    font-size: 12px;
    color: $color-dark1;
    opacity: 0.59;

    &--all {
      position: relative;
      font-size: 12px;
      color: $color-dark1;
      opacity: 0.59;
      text-decoration: underline;
    }
  }
}
