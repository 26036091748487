* {
  outline: none !important;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'Muli', sans-serif;
  overflow-x: hidden;
  font-size: rem-calc(14);
  line-height: 1.4285;
  color: $color-grey;
  background-color: $color-body-bg;
  padding-top: $topmenu-height;
  height: 100%;
  position: relative;
}

::-webkit-scrollbar {
  width: 20px;
  overscroll-behavior-y: none;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: rgba($color-grey, 0.15);
  border-radius: 16px;
  border: 6px solid rgba($color-black, 0);
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-grey;
  border: 6px solid rgba($color-black, 0);
  background-clip: padding-box;
}

::-webkit-scrollbar-button {
  display: none;
}

p:last-child {
  margin-bottom: 0;
}

.lead {
  font-size: rem-calc(18);
  font-weight: normal;
}

.color-orange {
  color: $color-orange2;
}

.color-dark {
  color: $color-dark;
}

.text-muted {
  color: rgba($color-dark, 0.8) !important;
}

.fz12 {
  font-size: rem-calc(12);
}

a {
  color: $color-orange2;

  &:hover {
    color: $color-orange3;
  }
}

.container {
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col,
[class*='col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.backdrop {
  z-index: 41;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
