.mark-right {
  .mark-right-btn {
    background: no-repeat
      url('../../assets/img/icon-track-assignment-mark-right.svg');
    background-position: 10%;
    padding: 5px 15px;
    background-color: white;
    border: 1.8px solid #29c16a;
    color: #29c16a;
    border-radius: 2px;
    margin-right: 15px;
    font-size: 16px;
    min-width: 155px;
    text-align: right;
    span {
      margin-right: 10px;
    }
    &:hover {
      background: no-repeat
        url('../../assets/img/icon-track-assignment-mark-right-hover.svg');
      background-position: 10%;
      background-size: 28px 28px;
      background-color: #29c16a;
      color: white;
    }
  }
  .bs-tooltip-left .arrow::before {
    border-left-color: #c4c4c4;
  }

  .tooltip-inner {
    max-width: 250px;
    max-height: 275px;
    text-align: left;
    overflow-x: auto;
    padding: 0px;
    background-color: #ffffff;
    border: 0.5px solid #c4c4c4;
    .popup-text {
      display: flex;
      flex-direction: column;
      background-color: #f4f4f4;
      color: #000000;
      border-bottom: 0.5px solid #c4c4c4;
      span {
        position: absolute;
        right: 10px;
        padding-right: 15px;
        padding-top: 5px;
        color: #c4c4c4;
        cursor: pointer;
        &:hover {
          color: #343434;
        }
      }
      p {
        padding: 10px 20px;
        color: #000000;
        font-size: 14px;
        font-family: 'Muli';
      }
    }
    .btn-container {
      padding: 10px;
      display: flex;
      justify-content: center;
      .confirm-right-btn {
        padding: 6px 40px;
        font-size: 18px;
        border: 1px solid #cf4a00;
      }
    }
  }
}
