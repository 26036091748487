[class^="icon-koo-"], [class*=" icon-koo-"] {
    background:url(./icon.png) no-repeat;display:inline-block;vertical-align:middle;}
.icon-koo-content, .icon-koo-assignment, .icon-koo-report, .icon-koo-logout{width:34px;height:34px;margin-right: 5px;}
.icon-koo-content{background-position: 0 0;}
.icon-koo-assignment{background-position: 0 -139px;}
.icon-koo-report{background-position: 0 -236px;}
.icon-koo-logout{background-position: 0 -301px;}
a:hover .icon-koo-content, a:focus .icon-koo-content, .active .icon-koo-content{background-position:0 -38px;}
a:hover .icon-koo-assignment, a:focus .icon-koo-assignment, .active .icon-koo-assignment{background-position:0 -91px;}
a:hover .icon-koo-report, a:focus .icon-koo-report, .active .icon-koo-report{background-position:0 -193px;}
a:hover .icon-koo-logout, a:focus .icon-koo-logout, .active .icon-koo-logout{background-position:0 -350px;}

.icon-koo-radio{width:20px;height:20px;background-position: -470px -57px;margin-top: -2px;margin-right: 7px;}
.custom-radio-btn:hover .icon-koo-radio, .custom-radio-btn:focus .icon-koo-radio{background-position: -470px 0;}
.custom-radio-btn.active .icon-koo-radio{background-position: -470px -139px;}

.icon-koo-edit{width:16px;height: 16px;background-position: -220px 0;}
.btn:hover .icon-koo-edit, .btn:focus .icon-koo-edit{background-position: -220px -59px;}
.btn.active .icon-koo-edit{background-position: -220px -132px;}

.icon-koo-layout-h{width: 48px;height:21px;background-position: -99px -4px;}
.btn-layout:hover .icon-koo-layout-h, .btn-layout:focus .icon-koo-layout-h{background-position: -99px -61px;}
.btn-layout.active .icon-koo-layout-h{background-position: -99px -125px;}
.icon-koo-layout-v{width:21px;height:41px;background-position: -179px 0;}
.btn-layout:hover .icon-koo-layout-v, .btn-layout:focus .icon-koo-layout-v{background-position: -179px -56px;}
.btn-layout.active .icon-koo-layout-v{background-position: -179px -116px;}

.icon-koo-multiplication-table, .icon-koo-addition, .icon-koo-subtraction, .icon-koo-multiplication, .icon-koo-division, .icon-koo-mixed1, .icon-koo-mixed2{
    width:40px;height:40px;position: absolute;top:-8px;margin-left:10px;}
.icon-koo-multiplication-table{background-position:-102px -181px;}
.icon-koo-addition{background-position:-158px -181px;}
.icon-koo-subtraction{background-position:-213px -181px;}
.icon-koo-multiplication{background-position:-270px -181px;}
.icon-koo-division{background-position:-326px -181px;}
.icon-koo-mixed1{background-position:-375px -181px;width:79px;}
.icon-koo-mixed2{background-position:-462px -181px;width:79px;}

.icon-koo-addition-sm, .icon-koo-subtraction-sm, .icon-koo-multiplication-sm, .icon-koo-division-sm, .icon-koo-mixed1-sm, .icon-koo-mixed2-sm{
    width:27px;height:27px;position: relative;top:-4px;margin-right: 10px;vertical-align:middle;}
.icon-koo-addition-sm{background-position:-46px -248px;}
.icon-koo-subtraction-sm{background-position:-145px -248px;}
.icon-koo-multiplication-sm{background-position:-223px -248px;}
.icon-koo-division-sm{background-position:-293px -248px;}
.icon-koo-mixed1-sm{background-position:-388px -248px;width:57px;}
.icon-koo-mixed2-sm{background-position:-485px -248px;width:57px;}
.quiz-item:hover .icon-koo-addition-sm{background-position:-46px -294px;}
.quiz-item:hover .icon-koo-subtraction-sm{background-position:-145px -294px;}
.quiz-item:hover .icon-koo-multiplication-sm{background-position:-223px -294px;}
.quiz-item:hover .icon-koo-division-sm{background-position:-293px -294px;}
.quiz-item:hover .icon-koo-mixed1-sm{background-position:-388px -294px;}
.quiz-item:hover .icon-koo-mixed2-sm{background-position:-485px -295px;}

.icon-koo-play, .icon-koo-edit2, .icon-koo-edit3, .icon-koo-assign{width:22px;height:22px;display:inline-block;vertical-align:middle;}
.icon-koo-play{background-position:-314px -120px;}
.icon-koo-edit2{background-position:-363px -120px;}
.icon-koo-edit3{background-position:-395px -120px;}
.icon-koo-assign{background-position:-424px -120px;}
.quiz-item:hover .icon-koo-play{background-position:-314px -57px;}
.quiz-item:hover .icon-koo-edit2{background-position:-363px -57px;}
.quiz-item:hover .icon-koo-edit3{background-position:-395px -57px;}
.quiz-item:hover .icon-koo-assign{background-position:-424px -57px;}
.btn:hover .icon-koo-play, .btn:focus .icon-koo-play{background-position:-314px -3px;}
.btn:hover .icon-koo-edit2, .btn:focus .icon-koo-edit2{background-position:-363px -2px;}
.btn:hover .icon-koo-edit3, .btn:focus .icon-koo-edit3{background-position:-395px -2px;}
.btn:hover .icon-koo-assign, .btn:focus .icon-koo-assign{background-position:-423px -1px;}

.icon-koo-close{height:38px;width:38px;background-position: -257px -49px;}
.close:hover .icon-koo-close{background-position: -257px 0;}

.icon-koo-back{width:43px;height:43px;background-position: -44px 0;}
a:hover .icon-koo-back, a:focus .icon-koo-back{background-position: -44px -52px;}
.icon-koo-back2{width:46px;height:46px;background-position: -247px -336px;}
a:hover .icon-koo-back2, a:focus .icon-koo-back2{background-position: -247px -395px;}

.icon-koo-trash{width:19px;height:25px;background-position: 0 -395px;}

.icon-koo-randomize{width:23px;height:20px;background-position: -55px -133px;margin-right: 5px;}
.btn:hover .icon-koo-randomize, .btn:focus .icon-koo-randomize{background-position: -55px -111px;}
.icon-koo-present{background-position: -52px -203px;width:27px;height:27px;margin-right: 5px;}

.icon-koo-eye{width:20px;height: 13px;background-position: -56px -181px;margin-left: 5px;}
.btn:hover .icon-koo-eye, .btn:focus .icon-koo-eye{background-position: -56px -159px;}

.icon-koo-volume{width:46px;height:38px;background-position: -46px -331px;}
.btn:hover .icon-koo-volume{background-position: -46px -385px;}
.btn.active .icon-koo-volume{background-position: -174px -331px;}
.btn.active:hover .icon-koo-volume{background-position: -174px -385px;}

.icon-koo-close2{width:30px;height:30px;background-position: -323px -335px;}
.btn:hover .icon-koo-close2{background-position: -323px -389px;}

.icon-koo-skip, .icon-koo-prev{width:21px;height:37px;background-position:-409px -334px;position: relative;top:-3px;margin-right:15px;}
.icon-koo-prev{background-position:-409px -383px;}

.icon-koo-times-red{width:27px;height: 27px;background-position: -270px -115px;}
.icon-koo-check-green{width:27px;height:27px;background-position: -120px -336px;}
.icon-koo-calendar{width: 49px;height:49px;background-position: -464px -336px;}
