.assign-admin {
  .form-check {
    padding: 0;
  }
  [type='radio']:checked + label:after {
    content: '';
    width: 11px;
    height: 11px;
    background-color: #ff6243;
    position: absolute;
    top: 2px;
    left: 1px;
    border-radius: 100%;
    -webkit-transition: background-color 0s ease;
    transition: background-color 0s ease;
    border: solid 1px #fff;
  }
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    top: 4.8px;
    left: -15.5px;
    border-radius: 100%;
    -webkit-transition: background-color 0s ease;
    transition: background-color 0s ease;
  }
}
