.panel {
  .panel-header {
  }
  .panel-body {
    .col-form-label {
      line-height: 2;
    }
    .form-control-plaintext {
      span {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        small {
          font-size: 100%;
          color: rgb(129, 129, 129);
        }
      }
    }
  }
}
