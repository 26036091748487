@import '../../assets/scss/Colors.scss';
.page-nav {
  .pagination {
    justify-content: center;
  }

  .page-item {
    margin: 0 0.5em;

    &.active .page-link,
    &.active .page-link:focus,
    &.active .page-link:hover {
      background-color: $color-orange;
      color: $color-white;
    }

    .page-link {
      color: $color-dark1;
      border-color: $color-grey7;

      &:focus,
      &:hover {
        box-shadow: none;
        background-color: $color-orange13;
      }

      &:disabled {
        background-color: $color-grey2;
        color: $color-white;
      }
    }
  }

  &__search {
    align-self: center;
    margin-left: 1.5em;
    color: $color-dark1;

    input {
      width: 2em;
      margin: 0 0.25em;
      text-align: center;
    }
  }
}
