// Import Global Styles
@import 'assets/scss/Colors.scss';

.saved-worksheet {
  &__dropdown {
    .dropdown {
      &-menu {
        min-width: 3rem;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.26);
        top: -36px;
      }
      &-item {
        font-size: 14px;
        font-family: 'Muli';
        font-weight: 600;
        color: $color-orange0;
        padding: 10px 12px 10px 15px;
        display: inline-block;
        .icon {
          margin-right: 10px;
          width: 25px;
        }
        > * {
          vertical-align: -5px;
        }
        &:first-of-type {
          padding-top: 7px;
          .icon {
            vertical-align: -8px;
          }
        }
        &:hover,
        &:focus {
          color: $color-orange2;
          background: rgba($color-black, 0);

          .icon {
            &-pencil {
              background-image: url('../../../assets/img/icon-pencil-orange.svg');
            }
            &-print {
              background-image: url('../../../assets/img/print-icon-orange.svg');
            }
            &-share {
              background-image: url('../../../assets/img/icon-share-orange.svg');
            }
            &-trash {
              background-image: url('../../../assets/img/icon-trash-orange.svg');
            }
          }
        }
      }
      &-toggle.btn[aria-expanded='true']::after {
        color: $color-orange2;
      }
    }

    &.dropdown {
      .btn.dropdown-toggle {
        background-color: $color-white;
        border: none;

        @media screen and (max-width: 1199px) {
          padding: 6px;
        }

        &::after {
          font-size: 2rem;
          background: none;
          content: '...';
          width: 20px;
          height: 20px;
          color: $color-dark1;
          line-height: 6px;
          vertical-align: top;
        }

        &:hover::after {
          color: $color-orange2;
        }
      }
    }
  }
  &__dropdown &__action {
    &:hover,
    &:focus {
      background-color: $color-white;
      color: $color-orange2;
      display: block;
    }
  }
}
