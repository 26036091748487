@import '../../assets/scss/Variables.scss';
@import '../../assets/scss/Colors.scss';
@import '../../assets/scss/Functions.scss';

.page-empty-cart {
  min-height: 100%;
  color: $color-empty-cart-text;

  h1 {
    font-size: rem-calc(25);
    line-height: 1;
  }

  h2 {
    font-size: rem-calc(20);
    line-height: 1;
    margin-bottom: 1em;
  }

  &-btn {
    font-size: rem-calc(22);
    line-height: 1.5;
    padding: 0.375em 3.5em;
    border-radius: 4px;
  }

  &-image {
    margin-bottom: 2rem;
  }

  @media (max-width: 968px) {
    padding: 0 2rem;
  }
}
