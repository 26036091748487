.answer-correction {
  display: flex;
  justify-content: space-between;
}
.qs-student-answer {
  padding-left: 1rem;
  padding-bottom: 1rem;
  .student-answer-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    .student-answer-wrong {
      width: 20px;
      height: 20px;
    }
  }
}
