// Datepicker Region
.daterangepickercontainer {
  .daterangepicker {
    left: auto !important;
    right: 0;
  }
  .rangecontainer {
    display: none;
  }
  .buttonContainer {
    justify-content: center;
    width: 100%;
  }
}
.track-assignment-picker {
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  border-radius: 2px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  color: #202020;
  cursor: pointer;
}
.track-assignment-picker span {
  font-size: 15px;
}
.daterangepickercontainer .buttonContainer {
  justify-content: flex-end;
  width: 100%;
}
@media screen and (max-width: 639px) {
  .daterangepickercontainer {
    .daterangepicker {
      max-width: 320px;
      overflow: scroll;
      top: 0 !important;
      right: 100% !important;
      margin-right: 5px;
    }
  }
}
@media screen and (max-width: 499px) {
  .daterangepickercontainer {
    .daterangepicker {
      max-width: 320px;
      overflow: scroll;
      top: 60px !important;
      right: 0 !important;
      margin-right: 5px;
      max-height: 360px;
    }
    .activeNotifier {
      padding-bottom: 20px;
    }
    .buttonContainer {
      position: relative;
      justify-content: flex-start;
    }
  }
}
@media screen and (max-width: 399px) {
  .daterangepickercontainer {
    .daterangepicker {
      margin-right: 0;
      top: 100% !important;
      left: 0 !important;
    }
  }
}
